
import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal,InputGroup, Form ,Button} from "react-bootstrap";
import{getPlanInfo,sleep,getTokeninfo, UsergetInfobyname} from "../../Hooks/UseBackendAction/UseGetController"
import {UsecreateUser} from "../../Hooks/UseBackendAction/UseCreateUser.js"



const BuyModal = (props) =>{
    const[buy , setBuy] = useState(true)
    const [PlanInfo, setPlanInfo] = useState([])
    const [userName, setUserName] = useState(props.registerinfo.user_name)
    const [FirstName, setFirstName] = useState(props.registerinfo.first_name)
    const [LastName, setLastName] = useState(props.registerinfo.last_name)
    const [Plan, setPlan] = useState()
    const [Email, setEmail] = useState(props.registerinfo.email)
    const [MobileNo, setMobileNo] = useState(props.registerinfo.mobile_no)
    const [Password, setPassword] = useState("")
    const [ReferrerID, setReferrerID] = useState(props.registerinfo.referrerID)
    const [imagepath, set_imagepath] = useState(props.registerinfo.logoURI)
    const [file, set_file] = useState({})
    const [ImageURL, setImageURL] = useState("")
    const dispatch = useDispatch()
    const[Token,setToken] = useState("")
    const [tokeninfo,setTokeninfo] = useState([])
    const [passwordeye, setpasswordeye] = useState(false)
    const [userBalance,setuserBalance] = useState([])
    const [balance,setbalance] = useState(0);



    useEffect(() => {
       
        getplanData();
        
    }, [])

    const getplanData = async () => {
        var plandata = await getPlanInfo();
        setPlanInfo(plandata.data)
        setPlan(plandata.data[0].name)
        const tokeninfo = await getTokeninfo();
        // setToken(tokeninfo.data[0].symbol)
        setTokeninfo(tokeninfo.data)

        var UserInfo = await UsergetInfobyname(userName);
        if (UserInfo.success === true) {
            setuserBalance(UserInfo.data.balance)
            
        }
    };

    const getbalance = (value) =>{

        
        const balance =  userBalance.find((data,i)=>{return (i  == value)?data:0});
        
      
        if(balance > 0 ) {
            setbalance(Number (balance) / 10 ** 18)
        }else{
            setbalance(0)   
        }
       

    }

    const Buy = async (data) =>{

        dispatch(UsecreateUser(data))
        await sleep(1000)
        // if (localStorage.getItem("iscreate")) {
           
        //     setBuy(false)
        //     window.location.reload()
        // }

    }

    const showPassword = (e) => {

        var e = document.getElementById("Password");
        if (e.type === "password") {
            setpasswordeye(true)
            e.type = "text";
        } else {
            setpasswordeye(false)
            e.type = "password";
        }
    };

    const onSubmit = async () => {

       
       
        const UserData = {
            user_name: userName,
            first_name: FirstName,
            last_name: LastName,
            email: Email,
            mobile_no: MobileNo,
            plan: Plan === "Basic" ? 0 : 1,
            password: Password,
            referrerID: ReferrerID,
            logoURI: imagepath,
            token:Token

        };
      
        
         await Buy(UserData)
           
     };


    return(
        <div className="signinmodal ">
            <Modal id="isbuymodal" show={buy} size="sm" centered className="br-15 thememodal  ">
             
                <Modal.Body className="p-0 br-15 ">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>


                    <div className=" br-15 text-white  p-3 small-input noshadow">
                    <div className="px-4 text-center mb-4">
                                <h4 className="text-dark fw-600">Buy</h4>
                               
                            </div>
                       
                             <div className=''>
                                <label className="text-dark f-14">Plan</label>
                                <Form.Select aria-label="Default select example"
                                   required
                                   value={Plan}
                                   onChange={(e) => { setPlan(e.target.value) }}>
                                    {/* <option>Select Plan</option> */}
                                    {PlanInfo && PlanInfo.map((item) => {
                                                
                                                return <option >{item.name}</option>
                                            }
                                            )}
                                                </Form.Select>
                            </div>
                            
                           
                           <div className='me-3 w-full my-4'>
                           <label className="text-dark f-14">Currency</label>
                                                <Form.Select aria-label="Default select example" 
                                                required
                                                value={Token}
                                                // onChange={(e) => { setToken(e.target.value)}}
                                                onChange={(e) => { setToken(e.target.value);
                                                    var index = e.target.selectedIndex;
                                                    var optionElement = e.target.childNodes[index]
                                                    var option =  optionElement.getAttribute('data-id');
                                                    getbalance(option) }}
                                                >
                                                     <option>Select Currency</option>
                                                        {/* {tokeninfo && tokeninfo.map((item) => 
                                                
                                                 <option value={item.symbol}>{item.name}</option>
                                            )} */}
                                             <option data-id={tokeninfo[0]?.tokenId} value={tokeninfo[0]?.symbol}>{tokeninfo[0]?.symbol}</option>
                                             <option data-id={tokeninfo[0]?.tokenId} value={tokeninfo[2]?.symbol}>{tokeninfo[2]?.symbol}</option>
                                                    
                                                </Form.Select>
                                            <p className="text-dark f-12 text-right">Balance : {parseFloat(balance.toFixed(3))} {Token}</p>

                                            </div>
                                            <div className=" me-3 w-full my-4">
                                <Form.Label className="text-dark f-14">Password</Form.Label>
                                <InputGroup>
                                    <Form.Control type="password" 
                                    aria-label="Username"
                                   value={Password}
                                   id="Password"
                                   onChange={(e) => { setPassword(e.target.value) }}                                   
                                   placeholder="Password" required aria-describedby="basic-addon1" />
                                    {!passwordeye ? 
                                              <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showPassword}></Button>:
                                        <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showPassword}></Button>}
                                </InputGroup>
                            </div>
                 
                           <div className="text-end">
                             <button className="btn btn-theme my-3 " onClick={()=>onSubmit()}>Go</button></div>


                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default BuyModal