
  //  export const backurl      =    "http://localhost:8091" // local
  // export const backurl   =    "http://43.204.99.101:8091" // testnet
 export const backurl = "https://api.trademaxcoin.com" // mainnet

export const frontendurl  =     window.location.origin;
export const superAdminId = "TMC";
export const TENMINUTESINMILISECONDS = 600000;
export const TENMINUTESINSECONDS = 600;
export const ADMIN = "0x408379af9F4BE7CeEE73EC2A9c1482C89D82DBA3"; // admin
// export const ADMIN = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF";

export const BUSD = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
export const USDT = "0x55d398326f99059fF775485246999027B3197955";
export const AdminMail = "crexesindia@gmail.com"