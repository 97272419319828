import React,{useEffect, useState} from 'react';
// import Scrollbars from 'react-custom-scrollbars';
import {InputGroup,Form,Button,OverlayTrigger,Tooltip} from 'react-bootstrap'
import tether from '../../Assets/Images/tether.png'
import SelectTokenModal from '../Modals/SelectTokenModal';
import SelectOutTokenModal from '../Modals/SelectOuttokenmodal';
import RecentTransModal from '../Modals/RecentTransModal';
import { backurl } from '../../config/env';
import { getTokeninfo,GetUseAmountsOutPerToken,getsettinginfo} from "../../Hooks/UseBackendAction/UseGetController"
import {UseSwap} from "../../Hooks/UseBackendAction/UseCreateUser.js"
import $ from 'jquery';


const Exchange = () =>{ 
const[selecttoken, setSelectToken] = useState(false)
const[selectouttoken, setselectouttoken] = useState(false)
const[recenttrans, setRecentTrans] = useState(false)
const [inToken,setintoken] =  useState("")
const [outToken,setouttoken] =  useState("")
const [tokenoutImage,settokenoutimage] = useState("")
const [tokenImage,settokenimage] = useState("")
const [InputAmount,setInputAmount] = useState('')
const [outAmount,setoutAmount] = useState('')
const [Price,setPrice] = useState(0)
const [UserId, setUserId] = useState(sessionStorage.getItem("userId"))
const [FeeAmount, setFeeAmount] = useState(0)
const [status, setStatus] = useState("Swap")



const SelectinToken = (intoken,intokenimage) =>{
    setintoken(intoken)
    settokenimage(intokenimage)
    
}
const SelectoutToken = (outtoken,outtokenimage) =>{
    setouttoken(outtoken)
    settokenoutimage(outtokenimage)
    
}
useEffect(() => {
  
    gettokeninfo();
    
}, [])

const Onswap = async () => {
 const Swapamount = {
    inputToken: inToken,
    outputToken: outToken,
    inputAmount: InputAmount * 10 ** 18,
    user:UserId

    };
  await UseSwap(Swapamount)
};

const gettokeninfo = async () => {
   
    const tokeninfo = await getTokeninfo();
    setintoken(tokeninfo.data[0].symbol)
    setouttoken(tokeninfo.data[1].symbol)

    settokenimage(tokeninfo.data[0].logoURI)
    const feeAmount = await getsettinginfo();
    setFeeAmount(Number(feeAmount.data[0].settingvalue))

        
 }

 const GetTokenPrice = async (inToken,outToken,value) => {
    
    const tokenprice = await GetUseAmountsOutPerToken(inToken,outToken,value);
    console.log("tokenprice",tokenprice);
    
    if(!tokenprice.success){
    setStatus("Insufficient Liquidity")
    document.getElementById("swapbtn").classList.add("disabled")
    }else{
        setStatus("Swap")
        document.getElementById("swapbtn").classList.remove("disabled")
    }
    setPrice(Number(tokenprice?.tokenOutPrice))
 
    setoutAmount(tokenprice?.output / 10 **18)
}

return(
    <>
        {recenttrans && <RecentTransModal onDismiss={() => setRecentTrans(false)}/>}
        {selecttoken && <SelectTokenModal  data={outToken} selectfromtoken={SelectinToken} onDismiss={()=> setSelectToken(false)}/>} 
        {selectouttoken && <SelectOutTokenModal  data={inToken} selectoutToken={SelectoutToken} onDismiss={()=> setselectouttoken(false)}/>} 
        <div className='container container-theme exchange'>
            <div className='row justify-content-center'>
                <div className='col-xl-5 col-lg-6 col-md-8'>
                    <div className='whitebox'>
                       
                            <div>
                                <div className='d-flex align-items-center jc-between gap-2'>
                                    <div>
                                        <h5 class="graytxt pt-4 fw-600 f-18">Exchange 
                                            <OverlayTrigger placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2">
                                                Finding a token by searching for its name or symbol or by passing 
                                                its address bellow
                                            </Tooltip>}
                                            >
                                                <Button
                                                variant="light"
                                                className="d-inline-flex align-items-center tool-tip p-0"
                                                >
                                                <span className="ms-1 fa fa-question-circle-o"></span>
                                                </Button>
                                            </OverlayTrigger>
                                        </h5>
                                        <p>Click here to see the Transaction</p>
                                    </div>
                                    <div><button className='fa fa-exchange' onClick={() => setRecentTrans(true)}></button></div>
                                </div>
                                <hr />
                                <div className='graybox p-3 br-10'>
                                    <p>From</p>
                                    <InputGroup className="mb-3">
                                        <Form.Control 
                                        required
                                        value={InputAmount}
                                        onChange={(e) => { setInputAmount(e.target.value); GetTokenPrice(inToken,outToken,e.target.value);}}placeholder='0.0' aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        />
                                        <Button variant="outline-dark" onClick={() => setSelectToken(true)}  id="button-addon2">
                                        <img src={backurl + `/Images/${tokenImage}`} />   {inToken} <span className='fa fa-chevron-down f-14'></span>
                                             {/* Select a currency <span className='fa fa-chevron-down f-14'></span> */}
                                        
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='text-center'>
                                    <span className='fa fa-arrow-down'></span>
                                </div>
                                <div className='graybox p-3 br-10'>
                                    <p>To</p>
                                    <InputGroup className="mb-3">
                                        <Form.Control 
                                      
                                        required
                                        value={outAmount}
                                         placeholder='0.0' aria-label="Recipient's username"
                                        
                                        aria-describedby="basic-addon2"
                                        />
                                        <Button variant="outline-dark" onClick={() => setselectouttoken(true)} id="button-addon2">
                                        {/* <img src={backurl + `/Images/${tokenoutImage}`} />    */}
                                        {outToken != "" ? outToken :"Select a currency"} <span className='fa fa-chevron-down f-14'></span>
                                        </Button>
                                    </InputGroup>
                                </div>
                                <div className='text-center'>
                                    {InputAmount >0 ?
                                    <button className='btn btn-theme m-auto mt-4' id="swapbtn" onClick={Onswap}>{status}</button>  :
                                    <button className='btn btn-theme m-auto mt-4'  disabled>{status}</button>}
                                    </div>
                                </div>
                    </div>
                    <div className='whitebox mb-4'>
                        <div className='d-flex jc-between'>
                            <p >Trade</p>
                            <p><span className='text-success'><b>{inToken !== "TMC" ? "Buy": "Sell"} </b></span> </p>
                        </div>
                        <div className='d-flex jc-between'>
                            <p>Fee</p>
                            <p className='themeclr'><b>{ inToken != "TMC" ? 0 : Number(InputAmount) +  Number(FeeAmount)} %</b></p>
                        </div>
                        <div className='d-flex jc-between'>
                            <p>Price</p>
                            <p>{Price} TMC</p>
                        </div>
                    </div>
                </div>
            </div>
         </div>
        </>
    )
}

export default Exchange