import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal,InputGroup, Form ,Button} from "react-bootstrap";
import { getsettinginfo } from "../../Hooks/UseBackendAction/UseGetController"
import { backurl } from "../../config/env";
import {UseEditProfile} from "../../Hooks/UseBackendAction/UseCreateUser.js"
import profimg from '../../Assets/Images/profile.png'
import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from '../../Hooks/UseToast'


const EditProf = (props) =>{
    const[editprofile , setEditProfile] = useState(true)
    const[editableP , setEditableP] =useState(false)
    const [userid,setuserid] =useState(props.record.userInfo.userId);
    const [UserName, setUserName] = useState(props.record.user_name)
    const [FirstName,setFirstName] =useState(props.record.first_name);
    const [LastName, setLastName] = useState(props.record.last_name)
    const [Email, setEmail] = useState(props.record.email)
    const [Address, setAddress] = useState(props.record.address)
    const [MobileNo, setMobileNo] = useState(props.record.mobile_no)
    const dispatch = useDispatch()
    const [imagepath, set_imagepath] = useState("")
    const [userhomeaddress,setuserhomeaddress] = useState(props.record.userHomeAddress)
    const [image, set_image] = useState(props.record.logoURI)
    const [file, set_file] = useState({})
    const [ImageURL, setImageURL] = useState("")
    const [imagesize, setimagesize] = useState(0)

    useEffect(() =>{
        getdata()
    },[FirstName])

    const getdata = async () =>
    {
        var settingdata = await getsettinginfo();
        setimagesize(Number(settingdata.data[10].settingvalue)) // image upload
    }

    const ImageUpload = event => {
        var name = event.target.value;
        set_file(event.target.files[0]);
        var size = Number(event.target.files[0].size);
        if(size > imagesize  * 1000){
             toast.error(`Please select image size less than ${imagesize} KB`, {
                position:position.position,
                style:style,
                iconTheme: iconTheme,
            })
             
        }else{
        set_imagepath(name);
        var url = URL.createObjectURL(event.target.files[0])
        setImageURL(url)
        }
    };

   
    const onEditSubmit = async (e) => {

        e.preventDefault();
        const editprofiledata = {
                userId : userid,
             user_name: UserName,
            first_name: FirstName,
            last_name: LastName,
            // email: Email,
            userHomeAddress:userhomeaddress,
            mobile_no: MobileNo,
            file: file!={} ? file : imagepath,
           
        };
           await UseEditProfile(editprofiledata)
           
           
    }

      
    return(
        <div className="signinmodal ">
            <Modal show={editprofile} centered size="lg" className="br-15 thememodal editprofmodal">
             
                <Modal.Body className="p-0 br-15 ">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <form onSubmit={onEditSubmit}className="d-lg-flex bgblue br-15">
                        <div className="signin py-5 px-2 w-50 h-44 mx-auto">
                        <div className="showin991">
                            <div className="px-4">
                                <h4 className="themeclr fw-600">My Profile</h4>
                            </div>
                            <hr className="themehr" />
                            </div>
                            <div className="editimg mb-4 mx-auto">
                               
                            {/* <img src={profimg} /> */}
                            {image != "" && ImageURL.length !== 0 ? <>{ImageURL && ImageURL.length > 0 ?
                                        <img src={ImageURL} /> :  <img src={backurl+`/Images/${image}`} />
                                    }</> : <>{ImageURL && ImageURL.length > 0 ?
                                        <img src={ImageURL} /> :   <img src={profimg} />
                                    } </>}
                                
                                <div className="fileupload">
                               
                                        <span className="fa fa-pencil" ></span>
                                        <input type="file"
                                        accept=".jpg,.jpeg,.png"
                                        onChange={ImageUpload}
                                        
                                        />
                                </div>

                               
                            </div>
                        <div className=" p-4">
                            <div className="d-flex justify-content-start align-items-center">
                            <lable>User Name : &nbsp;</lable>
                                <p contentEditable={editableP} className="me-2 mb-0">{UserName}</p>
                              
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                            <lable>First Name : &nbsp;</lable>
                                <p contentEditable={editableP} className="me-2 mb-0">{FirstName}</p>
                              
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                            <lable>Last Name : &nbsp;</lable>
                                <p contentEditable={editableP} className="me-2 mb-0">{LastName}</p>
                              
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                            <lable>Email Id : &nbsp;</lable>
                                <p contentEditable={editableP} className="me-2 mb-0">{Email}</p>
                              
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                            <lable>Wallet Address :    &nbsp;</lable>
                                <p contentEditable={editableP} className="me-2 mb-0">{`${Address.substring(0, 7)}...${Address.substring(35, 42)}`} </p>
                              
                            </div>
                        </div>
                            
                        </div>
                        <div className="signup bg-white pt-md-5 pt-4 w-50 h-44 small-input noshadow">
                            <div className="hidein991">
                            <div className="px-4">
                                <h4 className="themeclr fw-600">My Profile</h4>
                            </div>
                            <hr className="themehr" />
                            </div>
                            <div className=" p-4">
                                {/* <div className="d-lg-flex jc-between"> */}
                                <div className="mb-3 me-3 w-full"> 
                                    <Form.Label>First Name</Form.Label>
                                    <InputGroup> 
                                        <Form.Control value={FirstName} onChange={(e) => { setFirstName(e.target.value) }} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
                                      
                                       
                                    </InputGroup>
                                </div>  
                                <div className="mb-3 me-3 w-full"> 
                                    <Form.Label>Last Name</Form.Label>
                                    <InputGroup > 
                                        <Form.Control value={LastName} onChange={(e) => { setLastName(e.target.value) }} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
                                       
                                    </InputGroup>
                                </div>  
                           
                                {/* </div> */}
                               
                                <div className="d-lg-flex jc-between">
                                <div className="mb-3 me-3 w-full"> 
                                <Form.Label>Residential Address</Form.Label>
                                    <InputGroup > 
                                        <Form.Control 
                                        as="textarea"
                                        rows="4"
                                        value={userhomeaddress} 
                                        onChange={(e) => { setuserhomeaddress(e.target.value) }}
                                        placeholder="Residential Address" 
                                        aria-label="Username" 
                                        aria-describedby="basic-addon1"/>
                                        
                                    </InputGroup>
                               
                                </div>
                                </div>
                                <div className="d-lg-flex jc-between">
                                <div className="mb-3 me-3 w-full"> 
                                <Form.Label>Phone</Form.Label>
                                    <InputGroup> 
                                        <Form.Control 
                                         value={MobileNo} 
                                         pattern="[1-9]{1}[0-9]{9}"
                                         maxlength="10"
                                         title="Enter 10 digit Mobile Number"
                                        onChange={(e) => { setMobileNo(e.target.value) }}placeholder="Mobile Number" aria-label="Username" aria-describedby="basic-addon1"/>
                                        
                                    </InputGroup>
                               
                                </div>
                                </div>

                            <div className="text-end mt-4">
                                <button className="btn btn-theme">Update</button>
                            </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default EditProf