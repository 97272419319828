import React, { useState,useEffect } from "react";

import { Modal,InputGroup, Form ,Button, ProgressBar} from "react-bootstrap";

import profimg from '../../Assets/Images/profile.png'
import {Useuploadfile} from "../../Hooks/UseBackendAction/UseCreateUser.js"
import { getsettinginfo } from "../../Hooks/UseBackendAction/UseGetController"
import {style,iconTheme,position} from '../../Hooks/UseToast'
import toast, { Toaster } from 'react-hot-toast';


const UpDocument = (props) =>{
    const[updocument , setUPDocument] = useState(true)
    const [imagepath, set_imagepath] = useState("")
    const [file, set_file] = useState({})
    const [password, setpassword] = useState("")
    const [fileType, setfileType] = useState("")
    const [UserEmail , setEmail] = useState(sessionStorage.getItem("userLoginmail"))
    const [username,setusername] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    const [Level1,setLevel1] = useState(0)
    const [Level2,setLevel2] = useState(0)
    const [Level3,setLevel3] = useState(0)
    const [passwordeye, setpasswordeye] = useState(false)
    const [imagesize, setimagesize] = useState(0)

    // const[modalwidth, setModalWidth] = useState(true)

    const FileUpload = event => {
        var name = event.target.value;
        
        var size = Number(event.target.files[0].size);
        if(size > imagesize  * 1000){
            toast.error(`Please select image size less than ${imagesize} KB`, {
                position:position.position,
                style:style,
                iconTheme: iconTheme,
            })
            
        }else{
        setLevel2(70)
        set_file(event.target.files[0]);
        set_imagepath(name);
        
        }
    };
    
    const showPassword = (e) => {

        var e = document.getElementById("Password");
        if (e.type === "password") {
            setpasswordeye(true)
            e.type = "text";
        } else {
            setpasswordeye(false)
            e.type = "password";
        }
    };
    useEffect(() =>{
        getdata()
    },[])
    const getdata = async () =>
    {
        var settingdata = await getsettinginfo();
        setimagesize(Number(settingdata.data[10].settingvalue)) // image upload
    } 
const onSubmit = async () => {

    const fileData ={
        user_name:username,
        FileType:fileType,
        password: password,
        file:file
       };
       
       await Useuploadfile(fileData)
       setLevel3(100)
    }
    return(
        <div className="signinmodal ">
            <Modal centered show={updocument} size="lg" className="br-15 thememodal editprofmodal selectdoc">
             
                <Modal.Body className="p-0 br-15 ">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <div className="d-lg-flex bgblue br-15">
                        <div className="signin py-lg-5 py-3 w-50 h-44 ">
                            <div className="px-3">
                            <h4>Upload Document</h4>
                            <p className="f-14">Upload Your KYC Document</p>
                            </div>
                            <hr className="themehr" />
                            <ul className="p-3 doclists mb-0">
                                <li>
                                    <div className="d-flex jc-between">
                                        <p>Select Document</p>
                                        <button className="bg-danger"><span className="fa fa-minus"></span></button>
                                    </div>
                                    <ProgressBar now={Level1} />
                                </li>
                                <li>
                                    <div className="d-flex jc-between">
                                        <p>Upload</p>
                                        <button className="bg-success"><span className="fa fa-recycle"></span></button>
                                    </div>
                                    <ProgressBar now={Level2} />
                                </li>
                                <li>
                                    <div className="d-flex jc-between">
                                        <p>Status</p>
                                        <button className="bg-primary"><span className="fa fa-check"></span></button>
                                    </div>
                                    <ProgressBar now={Level3} />
                                </li>
                            </ul>
                        </div>
                        <div className="signup bg-white pt-lg-5 pt-3 w-50 h-44 small-input noshadow">
                            
                            <div className=" p-4">
                               
                                   
                                    <div className='mb-4'>
                                                <label>Document</label>
                                                <Form.Select aria-label="Default select example"
                                                value={fileType}
                                                required
                                                onChange={(e) => { setfileType(e.target.value);setLevel1(30) }}>
                                                    <option>Select Document </option>
                                                    <option value="PAN">PAN CARD</option>
                                                    <option value="AADHAR">AADHAR CARD</option>
                                                    
                                                </Form.Select>
                                            </div>
                               
                                <label>upload File</label>
                                <div className=" bg-white dashedbord">
                                    <div className="upimg  m-2">
                                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                                    <p>Drag files here to upload or <div className="selectbtn"> <button>{imagepath ? imagepath : "Select a file"}</button> 
                                    <Form.Control 
                                         required
                                         placeholder="select a file" 
                                         value={imagepath}
                                         type="file" 
                                         size="lg" 
                                         onChange={FileUpload}/>
                                        </div> from your device </p>
                              
                                    </div>
                                   
                                    
                                </div>
                                <div className="mt-2 p-6">
                                <div className=" mb-3 small-input">
                                <label>Password</label>
                                <InputGroup>
                                    <Form.Control 
                                    placeholder="Password" 
                                    value={password}
                                    id="Password"
                                    required
                                    onChange={(e) => { setpassword(e.target.value) }}
                                    type="password"  />
                             {!passwordeye ? 
                                              <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showPassword}></Button>:
                                        <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showPassword}></Button>}
                            </InputGroup>
                                    
                                        </div>   
                                    </div>
   
                            

                            <div className="text-end mt-4">
                                {fileType != "" && imagepath != "" && password != ""?
                                <button onClick={() => onSubmit()} className="btn btn-theme">Upload</button>:
                                <button  disabled className="btn btn-theme">Upload</button>}
                            </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default UpDocument