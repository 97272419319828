export const  style = {
      
                        
    minWidth: '300px',
    minHeight: '55px',
    border: '1px solid #092e87',
    padding: '16px',
    color: '#092e87'
    
}

export const iconTheme = {
    primary: '#3191db',
    secondary: '#092e87',
}

export const  position= {
    position: "top-right"
}