
import React, { useState ,useEffect} from "react";
import { Modal,InputGroup, Form ,Button} from "react-bootstrap";
import img1 from '../../Assets/Images/tether.png'
import coin3 from '../../Assets/Images/coin3.png'
import * as GetController from "../../Hooks/UseBackendAction/UseGetController"

const RecentTransModal = (props) =>{
const[recenttrans, setRecentTrans] = useState(true)
const[coindata,setcoindata] = useState([])
const [userid, setuserid] = useState(sessionStorage.getItem("userId"))


const getswapData = async () => {
       
    var TransactionInfo = await GetController.UserTransactions(userid);
   
    setcoindata(TransactionInfo.data);
    
   
   
};
useEffect(() => {
 
    getswapData();
    
}, [])

const UseSwapTransactions = ()=>{
    

if(coindata.length == 0) {return ( <li className='graybox br-1 p-2'><p className='mb-0 currency'> <b>No recent transactions</b></p></li>) }

return (  coindata && coindata.map((item) => (item.flow === "SWAP" ? <> 
      <li className='graybox br-10 p-2 in '>
     <div className='row'>
         <div className='col-md-4 col-sm-12 mb-md-0 mb-3'>
        <div className='d-flex'>               
        <img src={coin3} />                 
        <div>
                <p className='mb-0 currency'> <b>{(item.amount / 10  ** 18).toFixed(5)} {item.symbol || "USD"}</b></p>
                
                 <p className='mb-0 f-14'>{new Date(parseInt(item.createdAt)).toLocaleString("es-US")}</p>
                
                 </div>
             </div>
        </div>
        <div className='col-md-4 col-6'>
        <p className='mb-0'>From</p>
                               <p className={`${item.type === "TMC" ? "text-danger" : "text-success"} mb-0`}><b>{item.type}</b></p>
                               <p className='mb-0 '> <b>{(item.to / 10  ** 18).toFixed(5)} </b></p>
        </div>
        
        <div className='col-md-4  col-6'>
        <div>
         <p className='mb-0'>To</p>
         <p className='mb-0'><b>{item.symbol}</b></p>
         <p className='mb-0 '> <b>{(item.amount / 10  ** 18).toFixed(5)} </b></p>
         </div>
         </div>
     </div>
    
 </li></> : <></> )));
}


    return(
        <div className="signinmodal ">
            <Modal  show={recenttrans} size="md" centered className="br-15 thememodal  ">
             
                <Modal.Body className=" br-15 history">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>

                   <h6>Recent transactions</h6> 
                   <hr className="themehr" />
                   {/* <p>Please connect your wallet to view your recent transactions</p> */}
                   <div className="historyscroll recenttrans">
                    <ul>
                   
                    { coindata && UseSwapTransactions() }       
                             
                                    
                    </ul>
                   </div>
                   <div className="text-end">
                     <button className="btn btn-theme my-4" onClick={() => props.onDismiss()}>Close</button>
                   </div>
                   
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default RecentTransModal