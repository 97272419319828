import {backurl} from '../../config/env';
import axios from "axios";

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const getPlanInfo = async() => {
    
    const PlanInfo =  await   axios.get(`${backurl}/api/plan/info`)
          return PlanInfo.data;
 }

 export const getAlluserInfo = async() => {
    
      const userinfo =  await   axios.get(`${backurl}/api/user/all/info`)
            return userinfo.data;
   }

   export const getAllRegisteruserInfo = async() => {
    
      const userinfo =  await   axios.get(`${backurl}/api/register/all/info`)
            return userinfo.data;
   }
   

 export const getTokeninfo = async() => {
    
      const Tokeninfo =  await   axios.get(`${backurl}/api/asset/details`)
            return Tokeninfo.data;
   }

   export const getTmcPrice = async() => {
    
      const price =  await   axios.get(`${backurl}/api/getTMC/price`)
      return price.data;
   }
   

   export const getfeeinfo = async() => {
    
      const Feeinfo =  await   axios.get(`${backurl}/api/fee/data`)
            return Feeinfo.data;
   }

 export const UsergetInfobyid = async(Userid) => {
   
    const userinfo =  await   axios.get(`${backurl}/api/user/info/${Userid}`)
          return userinfo.data;
 }
export const getDailymintdata = async(user)=> {
   
   const userinfo =  await   axios.get(`${backurl}/api/user/daily/mint/${user}`)
         return userinfo.data;
}
 

 export const UsergetInfobyname = async(UserName) => {
   
      const userinfo =  await   axios.get(`${backurl}/api/user/infobyname/${UserName}`)
            return userinfo.data;
   }

export const UserTransactions = async(userid)=>{
      const userinfo =  await   axios.get(`${backurl}/api/user/transactions/${userid}`)
      return userinfo.data;
}
 

 export const UserreferrergetInfo = async(Userid) => {
   
   const userinfo =  await   axios.get(`${backurl}/api/user/referrerinfo/${Userid}`)
         return userinfo.data;
}
 

 export const UsergetRegisterInfo = async(Username) => {
    
   
      const userinfo =  await   axios.get(`${backurl}/api/user/register/info/${Username}`)
            return userinfo.data;
   }

   export const getdeposittoken = async() => {
    
      const depositinfo =  await   axios.get(`${backurl}/api/admin/token/info`)
      return depositinfo.data;
   }



   // export const UserBinaryIncome = async(Userid) => {
   
   //    const userbinaryIncome =  await   axios.get(`${backurl}/api/user/binaryIncome/${Userid}`)
    
   //    let usdvalue = 0;
   //      if(Number(userbinaryIncome?.data?.BinaryIncome) > 0){
   //             await axios.get("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD")
   //          .then (data => {
   //              usdvalue = Number(data.data.USD) * Number(userbinaryIncome?.data?.BinaryIncome);
                
   //          })
            
   //    }
   //    return usdvalue;
   // }

   export const UserBinaryIncome = async(Userid) => {
   
      const userbinaryIncome =  await   axios.get(`${backurl}/api/user/binaryIncome/${Userid}`)
   
      var  data = { 
         income : userbinaryIncome.data?.BinaryIncome, 
         leftIncome: userbinaryIncome.data?.leftIncome,
         rightIncome:userbinaryIncome.data?.rightIncome 
      };
         
         return data ;
    }
      
   

   export const UsetokeninfoById = async(tokenid) => {
   
      const tokeninfo =  await   axios.get(`${backurl}/api/token/info/${tokenid}`)
            return tokeninfo.data;
   }

  

   export const UseUSDValue = async(value) => {
     
   
     
      let usdvalue = 0;
        if(Number(value) > 0){
               await axios.get("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD")
            .then (data => {
                usdvalue = Number(data.data.USD) * Number(value);
                
            })
}

      return usdvalue;
   }

export const getsettinginfo = async() => {
     
    
      const Settinginfo =  await   axios.get(`${backurl}/api/admin/setting/info`)
            return Settinginfo.data;
   }


   export const UserRoyalIncome = async(Userid) => {
     
   
      const userRoyalIncome =  await   axios.get(`${backurl}/api/user/royalIncome/${Userid}`)
            return userRoyalIncome?.data;
   }


   export const UserLevelRewards = async(Userid) => {
     
   
      const userRoyalIncome =  await   axios.get(`${backurl}/api/user/levelIncome/${Userid}`)
            return userRoyalIncome?.data;
   }

   export const UserBinaryRewards = async(Userid) => {
    
      const userRoyalIncome =  await   axios.get(`${backurl}/api/user/binaryIncome/${Userid}`)
      return userRoyalIncome?.data;
     
   }

   export const GetUseAmountsOutPerToken = async(intoken,outtoken,Amount) => {
    
      const Tokenamountpertoken =  await axios.get(`${backurl}/api/exchange/${intoken}/${outtoken}/${Amount}`)
      
      return Tokenamountpertoken?.data;
     
   }

   export const UserMining = async(Userid) => {
    
      const UserMiningincome =  await   axios.get(`${backurl}/api/user/mining/income/${Userid}`)
      return UserMiningincome?.data;
     
   }



   export const UseGEtTokenPrice = async(token) => {
    
      const tokenPrice =  await   axios.get(`${backurl}/api/asset/price/${token}`)
      return tokenPrice?.data;
     
   }


   export const getContactinfo = async() => {
     
    
      const Contactinfo =  await   axios.get(`${backurl}/api/contact/info`)
            return Contactinfo.data;
   }
  

   export const getTotalSupply = async(token) => {
     
       const TotalSupply =  await   axios.get(`${backurl}/api/total/supply/${token}`)
     
      return TotalSupply.data;
   }



   export const getSubscriperinfo = async() => {
     
    
      const Contactinfo =  await   axios.get(`${backurl}/api/Subscriper/info`);
    
            return Contactinfo.data;
   }
