import * as actionTypes from '../../store/actions';
import * as config from "../../config/env";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
import {style,iconTheme,position} from '../UseToast'
import { sleep } from "./UseGetController";
import { UpdateRefreshRoken, UseHeaders} from './UseCreateUser.js';

export const UseTransfer = (param)=>(dispatch)=> { 
    const headers =  UseHeaders();   
    
    axios
        .post(config.backurl+"/api/user/transfer", param,headers)

        .then(async (res) =>{
            dispatch({
                type: actionTypes.USER_UPDATE,
                payload: res,
            })
            if (res.status === 200 && res.data.success === true) {
                
              localStorage.removeItem("isOTP") 
                toast.success(`${res.data.data}`,{
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }else if(res.data.isExpired == true){
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await UpdateRefreshRoken(res.data.isExpired,UseWithdraw,param)
            }
            else{
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                // await UpdateRefreshRoken(res.data.isExpired,UseTransfer,param)

            }
       
        }).catch(err =>{
           });
           
};


export const UseClaim = (param) => {

    const headers =  UseHeaders();
    axios
        .post(config.backurl + "/api/user/claim", param,headers)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
                
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)
               
                window.location.reload()

            }else if(res.data.isExpired == true){
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await UpdateRefreshRoken(res.data.isExpired,UseWithdraw,param)
            }
            else {
               
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                // await UpdateRefreshRoken(res.data.isExpired,UseClaim,param)
            }
        }
        ).catch(err => {
        }
        );

};

export const UseWithdraw = (param) => {

    const headers =  UseHeaders();
    axios
        .post(config.backurl + "/api/user/withdraw/:token", param,headers)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
               
                localStorage.removeItem("isOTP")   
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
               
                window.location.reload()

            } else if(res.data.isExpired == true){
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await UpdateRefreshRoken(res.data.isExpired,UseWithdraw,param)
            }
            else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );

};





