import React, {useState,useEffect } from "react";

import { Modal,Button, Form } from "react-bootstrap";

import profimg from '../../../Assets/Images/profile.png'
import {UseUpdateSetting} from "../../../Hooks/UseBackendAction/UseAdmin"

const UpdateSetting = (props) =>{

    const [updatesetting, setupdatesetting] = useState(true)
    const [id,setid] = useState(props?.record?._id)
    const [settingname,setsettingname] = useState(props?.record?.settingname)
    const [settingvalue,setsettingvalue] = useState(props?.record?.settingvalue)


    const onSubmit = async () => {

        const settingData = {

            id: id,
            settingname:settingname,
            settingvalue: settingvalue,
          };
         
          await UseUpdateSetting(settingData)
         
     };
 

    return(
        <div className="signinmodal editprofile">
                
            <Modal show={updatesetting} size="md" className="br-15 thememodal">
             
                <Modal.Body className="p-0 br-15">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <div className="d-flex bgblue align-items-center br-15 editprof">
                       
                        <div className=" br-15 bg-white pt-5 w-full h-44 small-input noshadow">
                            <div className="px-4">
                                <h4 className="themeclr fw-600">Setting</h4>
                                {/* <p>Lorem hg yeg e etyegt</p> */}
                            </div>
                       
                            <hr className="themehr" />


                            <div className=" p-4">
                               
                                
                                <div className="d-lg-flex jc-between">
                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Setting Name</Form.Label>
                                        <Form.Control type="text" 
                                        placeholder="" 
                                        required
                                        readOnly
                                        value={settingname}
                                        onChange={(e) => { setsettingname(e.target.value) }}/>
                                    </Form.Group>
                                   
                                </div>
                                <div className="d-lg-flex jc-between">
                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Setting Value</Form.Label>
                                        <Form.Control type="text" 
                                        placeholder=""
                                        required
                                        value={settingvalue}
                                        onChange={(e) => { setsettingvalue(e.target.value) }} />
                                    </Form.Group>
                                    
                                </div>

                            
                               

                            <div className="text-end mt-4">
                                <button className="btn btn-theme" onClick={() => onSubmit()}>Update</button>
                            </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default  UpdateSetting;

