import React ,{useState,useEffect} from 'react';
import HomeHeader from './HomeHeader';
import {InputGroup , Form} from 'react-bootstrap'
import Footer from './Footer';
import {NewContact} from "../Hooks/UseBackendAction/UseCreateUser.js"
import { validateUserContact ,countryList } from '../Hooks/UseValidation';
import {getsettinginfo} from "../Hooks/UseBackendAction/UseGetController"
import contactus from '../Assets/Images/contactus.png'



const Contactus = () =>{
    const [name,setname] = useState("")
    const [email,setemail] = useState("")
    const [phoneNo,setphoneNo] = useState("")
    const [country,setcountry] = useState("")
    const [comments,setcomments] = useState("")
    const [errors, seterrors] = useState({})
    const [CountryList,setcountryList] = useState(countryList)
    const [settingData, setsettingdata] = useState([])



    useEffect(() => {
        gettokeninfo()
       
    }, [])
    const gettokeninfo = async () => {
   
        const settingData = await getsettinginfo();
        setsettingdata(settingData.data)
    }
    const onSubmit = async (e) => {

        e.preventDefault();
        const UserData = {
            name: name,
            email: email,
            phoneNo: phoneNo,
            comments: comments,
            country: country,
            
            
        };
        
        const { errors, isValid } = await validateUserContact(UserData);
        if (!isValid) {
            seterrors(errors)
        }
        else {
           await NewContact(UserData)
           
        }

       

    };

    return(
        <div className='contactus homebg'>
            <HomeHeader />
            <h4 className='ps-sm-5 ps-3  mt-5 fw-600'>Contact us</h4>
            <div  className='container'>
            
                <div className="row pb-5 align-items-center">
                    <form onSubmit={onSubmit} className='col-md-7'>
                        <div className='whitebox small-input noshadow p-lg-5 p-3'>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className="mb-3 me-3">
                                        <Form.Label>Name</Form.Label>
                                        <InputGroup> <Form.Control 
                                          type="text"
                                          placeholder='Name'
                                          required
                                          value={name}
                                          onChange={(e) => { setname(e.target.value)}}>

                                          </Form.Control>
                                           </InputGroup>
                                           <span className="text-danger f-12 d-block text-left">{errors.name}</span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="mb-3 me-3">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup> <Form.Control type="text"
                                          placeholder='Email'
                                          required
                                          value={email}
                                          onChange={(e) => { setemail(e.target.value)}}
                                         /> </InputGroup>
                                <span className="text-danger f-12 d-block text-left">{errors.email}</span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="mb-3 me-3">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                    value={country}
                                                
                                    onChange={(e) => { setcountry(e.target.value)}}>
                                       
                                        <option >Select Country</option>
                                           {CountryList && CountryList.map((item) => 
                            
                                <option value={item.name}>{item.name}</option>
                                          )} 
                                    </Form.Select>
                                    <span className="text-danger f-12 d-block text-left">{errors.country}</span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className="mb-3 me-3">
                                        <Form.Label>Phone Number</Form.Label>
                                        <InputGroup> 
                                        <Form.Control 
                                        type="text"
                                        required
                                        value={phoneNo}
                                        placeholder='Phone Number'
                                        pattern="[1-9]{1}[0-9]{9}"
                                        title="Enter 10 digit Mobile Number"
                                        maxlength="10"
                                        onChange={(e) => { setphoneNo(e.target.value)}}/> </InputGroup>
                                         <span className="text-danger f-12 d-block text-left">{errors.phoneNo}</span>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="mb-3 me-3 themetextbox">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Comments</Form.Label>
                                            <Form.Control as="textarea"
                                            required
                                            placeholder='Comments'
                                            value={comments}
                                            onChange={(e) => { setcomments(e.target.value)}}
                                             rows={8} />
                                        </Form.Group>
                                        <span className="text-danger f-12 d-block text-left">{errors.comments}</span>
                                    </div>
                                </div>
                                <div className='my-4'>
                                    <button className='btn btn-theme'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='col-md-5'>
                        <img src={contactus} className="contactimg"/>
                        {/* <div className='whitebox p-lg-5 p-4'>
                            <h4 className="fw-600 text-muted mb-3">Address</h4>
                            <ul>
                                <li className='d-flex align-items-center mb-4 '>
                                    <span className='bluetxt fa fa-map-marker me-2'></span>
                                    <p className='mb-0 graytxt fw-500' >{settingData && settingData[19] && settingData[19].settingvalue}</p>
                                </li>
                                <li className='d-flex align-items-center mb-4'>
                                    <span className='bluetxt fa fa-phone me-2'></span>
                                    <p className='mb-0 graytxt fw-500'>Phone : {settingData && settingData[21] && settingData[21]?.settingvalue} </p>
                                </li>
                                <li className='d-flex align-items-center mb-4'>
                                    <span className='bluetxt fa fa-envelope me-2'></span>
                                    <p className='mb-0 graytxt fw-500'>Email : {settingData && settingData[20] && settingData[20].settingvalue} </p>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contactus