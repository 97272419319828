import axios from "axios";
import * as actionTypes from '../../store/actions';
import * as config from "../../config/env";
import isEmpty from "is-empty";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../store/setAuthToken";
import { sleep } from "./UseGetController";
import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from '../UseToast'
import $ from 'jquery';


export const UseHeaders= ()=>{
    const accessToken = sessionStorage.getItem("Token");
    
 const headers = {headers:{Authorization: `Bearer ${accessToken}`}
}
return headers;
}

export const UseRefreshToken = ()=>{
    
const refreshtoken = {refreshtoken:sessionStorage.getItem("Token")}
return refreshtoken;
}
export const UsecreateUser = (param) => (dispatch) => {
   

    var NewUserData = new FormData();
    if (!isEmpty(param.user_name)) NewUserData.append('user_name', param.user_name);
    if (!isEmpty(param.first_name)) NewUserData.append('first_name', param.first_name);
    if (!isEmpty(param.last_name)) NewUserData.append('last_name', param.last_name);
    if (!isEmpty(param.email)) NewUserData.append('email', param.email);
    if (!isEmpty(param.mobile_no)) NewUserData.append('mobile_no', param.mobile_no);
    NewUserData.append('plan', (param.plan));
    if (!isEmpty(param.password)) NewUserData.append('password', param.password);
    if (!isEmpty(param.confirmpassword)) NewUserData.append('confirmpassword', param.confirmpassword);
    if (!isEmpty(param.referrerID)) NewUserData.append('referrerID', param.referrerID);
    if (!isEmpty(param.token)) NewUserData.append('token', param.token);

    if (!isEmpty(param.logoURI)) {
        NewUserData.append('logoURI', param.logoURI);
    }
    axios
        .post(config.backurl + "/api/user/create", NewUserData)
        .then(async (res) => {
           
            dispatch({
                type: actionTypes.USER_ADD,
                payload: res,
            })
            if (res.status === 200 && res.data.success === true) {
                // localStorage.setItem("iscreate", " ")
                sessionStorage.setItem("userId", res.data.userid)

                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)
                $('#isbuymodal').hide();
                window.location.reload()
            } else {
                toast.error(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );

};

export const UseNewRegister = (param) => (dispatch) => {

    var NewUserData = new FormData();
    if (!isEmpty(param.user_name)) NewUserData.append('user_name', param.user_name);
    if (!isEmpty(param.first_name)) NewUserData.append('first_name', param.first_name);
    if (!isEmpty(param.last_name)) NewUserData.append('last_name', param.last_name);
    if (!isEmpty(param.email)) NewUserData.append('email', param.email);
    if (!isEmpty(param.mobile_no)) NewUserData.append('mobile_no', param.mobile_no);

    if (!isEmpty(param.password)) NewUserData.append('password', param.password);

    if (!isEmpty(param.referrerID)) NewUserData.append('referrerID', param.referrerID);
    if (!isEmpty(param.address)) NewUserData.append('address', param.address);

    if (!isEmpty(param.file)) {
        NewUserData.append('file', param.file);

    }

    axios
        .post(config.backurl + "/api/user/new/register", NewUserData)
        .then(async (res) => {
           
            dispatch({
                type: actionTypes.USER_ADD,
                payload: res,
            })
            if (res.status === 200 && res.data.success === true) {
                localStorage.setItem("isRegister", `${res.data.success}`)

                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl;

            } else {
                toast.error(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        });
};

export const UseEditProfile = (param)  => {


    var EditUserData = new FormData();
     if (!isEmpty(param.userId)) EditUserData.append('userId', param.userId);
     if (!isEmpty(param.user_name)) EditUserData.append('user_name', param.user_name);
    if (!isEmpty(param.first_name)) EditUserData.append('first_name', param.first_name);
    if (!isEmpty(param.last_name)) EditUserData.append('last_name', param.last_name);
    // if (!isEmpty(param.email)) EditUserData.append('email', param.email);
   if (!isEmpty(param.userHomeAddress)) EditUserData.append('userHomeAddress', param.userHomeAddress);
    
    if (!isEmpty(param.mobile_no)) EditUserData.append('mobile_no', param.mobile_no);
    if (!isEmpty(param.file)) {
        EditUserData.append('file', param.file);

    }
    const headers =  UseHeaders();
    axios
        .post(config.backurl + "/api/userprofile/update", EditUserData,headers)
        .then(async (res) => {
           
           
            if (res.status === 200 && res.data.success === true) {
               
                sessionStorage.setItem("username", res.data.user_name);
                localStorage.setItem("isEdit", `${res.data.success}`)

                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,                
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/dashboard"

            }else {
               
                    // await UpdateRefreshRoken(res.data.isExpired,UseEditProfile,EditUserData)
                    toast.error(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })
                }
        }
        ).catch(err => {
        }
        );
};

export const UseloginUser = (param) => (dispatch) => {

    let result;
    try {

        axios
            .post(config.backurl + "/api/user/login", param)
            .then(async (res) => {


                dispatch({
                    type: actionTypes.SET_CURRENT_USER,
                    payload: res,
                })

                if (res.status === 200 && res.data.success === true) {
                    sessionStorage.setItem("userId", res.data.user_id ? res.data.user_id : " ")
                    // sessionStorage.setItem("Token",res.data.accessToken)
                    sessionStorage.setItem("username", res.data.user_name?res.data.user_name: " ")
                    sessionStorage.setItem("userLoginmail",res.data.email ? res.data.email : " ")
                    sessionStorage.setItem("isauth",res.data.isAuth === true ? "true" : "false")
                    localStorage.removeItem("isRegister")
                  
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    }
                    )
                    await sleep(1000)
                    window.location.href = config.frontendurl + "/dashboard"
                } else {
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })
                }

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }
    return result;
};

export const CheckLoginOtp = (param) => {


    axios
        .post(config.backurl + "/api/user/checklogin/otp", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
                sessionStorage.setItem("Token", res.data.accessToken)
                localStorage.setItem("Token", res.data.accessToken)
                sessionStorage.setItem("username", res.data.user_name);
                localStorage.setItem("username", res.data.user_name);
                sessionStorage.setItem("userfname", res.data.firstName);
                sessionStorage.setItem("userlname", res.data.lastName);
                sessionStorage.removeItem("isauth")
                localStorage.setItem("isOTP","success")
                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                // $('#otpmodal').hide()
                // window.location.href = config.frontendurl + "/dashboard"
            } else {
                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                localStorage.setItem("isOTP","fail")
            }
        }
        ).catch(err => {
        }
        );
};

export const ResentOtp = (param) => {

    try {
        axios
            .post(config.backurl + "/api/user/Resend/otp", param)
            .then(async (res) => {

                if (res.status === 200) {
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};

export const ResetPassword = (param) => {


    try {

        axios
            .post(config.backurl + "/api/user/reset/password", param)
            .then(async (res) => {

                if (res.status === 200 && res.data.success === true) {
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }else{{
                    toast.error(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }}

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};


export const Useuserupgradplan = (param) => {


    axios
        .post(config.backurl + "/api/user/buy", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {

                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/dashboard"

            } else {
                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};
export const Useuploadfile = (param) =>  {


    var KycFiledata = new FormData();
    if (!isEmpty(param.user_name)) KycFiledata.append('user_name', param.user_name);
    if (!isEmpty(param.password)) KycFiledata.append('password', param.password);
    if (!isEmpty(param.FileType)) KycFiledata.append('FileType', param.FileType);
    if (!isEmpty(param.file)) {
        KycFiledata.append('file', param.file);

    }

    axios
        .post(config.backurl + "/api/user/kyc/file", KycFiledata)
        .then(async (res) => {
           
           
            if (res.status === 200 && res.data.success === true) {
               

                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/dashboard"

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};

export const UseRequestResetPassword = (param) => {


    axios
        .post(config.backurl + "/api/user/request/reset", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
                localStorage.setItem("forgotEmail", res.data.forgotemail);

                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};

export const UseRequestWithdrawResetPassword = (param) => {


    axios
        .post(config.backurl + "/api/user/request/withdraw/:token", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
                localStorage.setItem("forgotEmail", res.data.forgotemail);

                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};



export const UseForgotPassword = (param) => {


    axios
        .post(config.backurl + "/api/user/password/forgot", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
              
                localStorage.removeItem("forgotEmail");
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)
                $('#newpasswordmodal').hide();
                window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};

export const UseWithdrawForgotPassword = (param) => {


    axios
        .post(config.backurl + "/api/user/reset/withdraw/:token", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
              
                localStorage.removeItem("forgotEmail");
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)
                $('#newpasswordmodal').hide();
                window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};

export const UseisAuth = (param) => {


    axios
        .post(config.backurl + "/api/user/isauth/:token", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
                
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(3000)
               
                // window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const withdrawResetPassword = (param) => {
try {

        axios
            .post(config.backurl + "/api/user/withdraw/reset", param)
            .then(async (res) => {

                if (res.status === 200 && res.data.success === true) {
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }else{{
                    toast.error(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }}

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};


export const Check2FACode = (param) => {


    axios
        .post(config.backurl + "/api/user/authcode/:token", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
                sessionStorage.setItem("Token", res.data.accessToken)
                sessionStorage.setItem("username", res.data.user_name);
                sessionStorage.setItem("userfname", res.data.firstName);
                sessionStorage.setItem("userlname", res.data.lastName);
                sessionStorage.removeItem("isauth")
                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/dashboard"
            } else {
                toast.success(`${res.data.data}`, {
                   position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};



export const UseisKyc = (param) => {


    axios
        .post(config.backurl + "/api/user/isKyc/verify", param)
        .then(async (res) => {
           

            if (res.status === 200 && res.data.success === true) {
               
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                window.location.href = config.frontendurl + "/admin";
                // window.location.reload()

            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};
export const logoutUser = () => () => {
    sessionStorage.removeItem("userId");
   
    sessionStorage.removeItem("Token")
    localStorage.removeItem("Token")
    localStorage.removeItem("username")
    sessionStorage.removeItem("username")
    sessionStorage.removeItem("userfname")
    sessionStorage.removeItem("userlname")
    sessionStorage.removeItem("userLoginmail")
   
    setAuthToken(false);
    window.location.href = config.frontendurl;
};



export const NewAssetSave = (param) =>  {


    var assetdata = new FormData();
    if (!isEmpty(param.tokenAddress)) assetdata.append('tokenAddress', param.tokenAddress);
    if (!isEmpty(param.name)) assetdata.append('name', param.name);
    if (!isEmpty(param.symbol)) assetdata.append('symbol', param.symbol);
    if (!isEmpty(param.price)) assetdata.append('price', param.price);
    if (!isEmpty(param.file)) {
        assetdata.append('file', param.file);
    }
   axios
        .post(config.backurl + "/api/admin/asset/add/:token", assetdata)
        .then(async (res) => {
           
           
            if (res.status === 200 && res.data.success === true) {
               
               
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                $('#assetadd').hide()
                window.location.reload()
               
            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};



export const UpdateAssetSave = (param) =>  {
    var assetdata = new FormData();
    if (!isEmpty(param.tokenId)) assetdata.append('tokenId', param.tokenId);
    if (!isEmpty(param.tokenAddress)) assetdata.append('tokenAddress', param.tokenAddress);
    if (!isEmpty(param.name)) assetdata.append('name', param.name);
    if (!isEmpty(param.symbol)) assetdata.append('symbol', param.symbol);
    if (!isEmpty(param.price)) assetdata.append('price', param.price);
    if (!isEmpty(param.file)) {
        assetdata.append('file', param.file);
    }
   axios
        .post(config.backurl + "/api/admin/asset/update/:token", assetdata)
        .then(async (res) => {
           
           
            if (res.status === 200 && res.data.success === true) {
               
               
                toast.success(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
                await sleep(1000)
                $('#assetupdate').hide()
                window.location.reload()
               
            } else {
                toast.error(`${res.data.data}`, {
                    position:position.position,
                    style:style,
                    iconTheme: iconTheme,
                })
            }
        }
        ).catch(err => {
        }
        );
};

export const UpdateRefreshRoken = async(value,functionName,params) => {
   
    try{
        const refreshtoken = await UseRefreshToken();
    if(value)
    {axios.post(config.backurl + "/api/user/token/refresh",refreshtoken).then(async(result)=>{
      sessionStorage.setItem("Token", result.data.accessToken)
      await functionName(params)
    });
}
else{
    await sleep(1000)
    window.location.href = config.frontendurl;
}
    }catch (e) {
        console.log("error", e);
        return
    }
}


export const UseSwap = (param) => {


    try {

        axios
            .post(config.backurl + "/api/exchange/:input/:output", param)
            .then(async (res) => {

                if (res.status === 200 && res.data.success === true) {
                    toast.success(`${res.data.message}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }else{{
                    toast.error(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }}

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};


export const NewContact = (param) => {
   
try {

        axios
            .post(config.backurl + "/api/contact/add/:info", param)
            .then(async (res) => {

                if (res.status === 200 && res.data.success === true) {
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })
                    await sleep(1000)
                   
                    window.location.reload()

                }else{{
                    toast.error(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })

                }}

            })
            .catch(err => {

            }
            );

    }
    catch (e) {
        console.log("error", e);
    }

};


export const NewSuscriperContact = (param) => {
   
    try {
    
        
            axios
                .post(config.backurl+"/api/Subscriper/add/info", param)
                .then(async (res) => {
                    console.log("log",res);
                   
                    if (res.status === 200 && res.data.success === true) {
                        toast.success(`${res.data.data}`, {
                           position:position.position,
                            style:style,
                            iconTheme: iconTheme,
                        })
                        await sleep(1000)
                       
                        window.location.reload()
    
                    }else{{
                        toast.error(`${res.data.data}`, {
                           position:position.position,
                            style:style,
                            iconTheme: iconTheme,
                        })
    
                    }}
    
                })
                .catch(err => {
    
                }
                );
    
        }
        catch (e) {
            console.log("error", e);
        }
    
    };


export const UseDepositHash = (param) => {


        axios
            .post(config.backurl + "/api/tx/verify/:tx",param)
            .then(async (res) => {
               console.log("res",res);
    
                if (res.status === 200 && res.data.success === true) {
    
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    })
                    await sleep(1000)
                    window.location.href = config.frontendurl + "/dashboard"
    
                } else {
                    toast.success(`${res.data.data}`, {
                       position:position.position,
                        style:style,
                        iconTheme: iconTheme,
                    }
                    )
                }
            }
            ).catch(err => {
            }
            );
    
    };



export const GenerateOTP = (param) => {
        try {
        
                axios
                    .post(config.backurl + "/api/genrateotp", param)
                    .then(async (res) => {
                        console.log("res",res.data);
                       if(res.data.success === true){
                           localStorage.setItem("isOTP","null")
                         }
                //  $('#otpmodal').show()
                        if (res.status === 200 && res.data.success === true) {
                            toast.success(`${res.data.data}`, {
                               position:position.position,
                                style:style,
                                iconTheme: iconTheme,
                            })
        
                        }else{{
                            toast.error(`${res.data.data}`, {
                               position:position.position,
                                style:style,
                                iconTheme: iconTheme,
                            })
        
                        }}
        
                    })
                    .catch(err => {
        
                    }
                    );
        
            }
            catch (e) {
                console.log("error", e);
            }
        
};

export const UseAdminMail = (param) => {
    console.log("email",param);
    axios.post(config.backurl + "/api/admin/mail",param)
                .then(async (res) => {
                }
                ).catch(err => {
                }
                );
};

        