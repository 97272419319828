import React,{useState} from "react";
import {Form,Button,InputGroup} from 'react-bootstrap'
import {UseAdminLogin} from "../../Hooks/UseBackendAction/UseAdmin"

import logo from '../../Assets/Images/logo.svg'

const Login = () =>{

    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")
    const [passwordeye, setpasswordeye] = useState(false)



    const AdminLogin =async(data) =>{
        await UseAdminLogin(data)
        // await sleep(1000)
       
        
    }
    const onAdminLoginSubmit = async () => {

       const AdminLoginData = {
            
            email:Email,
            password: Password,
         };
        
         await AdminLogin(AdminLoginData)
        
    };


    const showPassword = (e) => {

        var e = document.getElementById("Password");
        if (e.type === "password") {
            setpasswordeye(true)
            e.type = "text";
        } else {
            setpasswordeye(false)
            e.type = "password";
        }
    };
    return(
        <div className="login">
            <div className="container container_theme">
              <div className="row align-items-center justify-content-center text-start" style={{minHeight:"100vh"}}>
                 <div className="col-xl-4 col-lg-5 col-md-7 col-sm-8">
                 <div className="text-center">
                    <img src={logo} />
                 </div> 
                    <div className="whitebox small-input noshadow">
                        <div className=" p-4 ">
                           <h4 className="mb-4 text-center">Login</h4>
                           <hr className="themehr mb-4"/>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                 type="text" 
                                 placeholder="name@example.com" 
                                 required
                                 value={Email}
                                 onChange={(e) => { setEmail(e.target.value) }}/>
                           
                            </Form.Group>
                           
                                <Form.Label>Password</Form.Label>
                                <InputGroup className="mb-5" controlId="exampleForm.ControlInput1"> 
                                <Form.Control type="password" 
                                 placeholder="*******" 
                                 required
                                 id="Password"
                                 value={Password}
                                 onChange={(e) => { setPassword(e.target.value) }}
                                 />
                          
                          {!passwordeye ? 
                                              <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showPassword}></Button>:
                                        <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showPassword}></Button>}
                                    </InputGroup> 
                            {/* <Button variant="link" className="txtdarkblue ps-0 f-14 fw-500">Forgot Password?</Button> */}
                            <div className="text-end">
                                <button className="btn btn-theme  mt-4"onClick={()=> onAdminLoginSubmit()}>Login</button>
                            </div>
                        </div>
                    </div>
                 </div>
              </div>
            </div>
        </div>
    )
}

export default Login 