import React, { useState } from "react";
import { Modal,Form } from "react-bootstrap";
import {UseRequestResetPassword} from "../../Hooks/UseBackendAction/UseCreateUser.js"
import { sleep } from "../../Hooks/UseBackendAction/UseGetController.js";
import {validaterequestreset} from "../../Hooks/UseValidation"

const ForgotPwd = (props) =>{
    
    const [forgotpwd , setForgotPwd] = useState(true)
    const [Email, setEmail] = useState("")
    const [errors, seterrors] = useState({})

    const userResetpassword = async(data) =>{
        await UseRequestResetPassword(data)
        await sleep(1000)
    
    if (localStorage.getItem("forgotEmail")) {
         setForgotPwd(false)
        }
    }
    const onSubmit = async () => {
         const userData = {
            user_name: Email,
      
        };

        // const { errors, isValid } = await validaterequestreset(userData);
        // if (!isValid) {
            
        //     seterrors(errors)
        // }
        // else {
           
            await userResetpassword(userData)
           
        // }
       
      
    };
  
    return(
        <div className="signinmodal ">
            <Modal show={forgotpwd} centered size="md" className="thememodal paystatus ">
              <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                <Modal.Body className="p-0">
                    <div className="d-flex flex-1">
                        <div className="text-center leftcont px-3 py-5 bgblue" style={{borderRadius:"15px 0 0 15px"}}>
                            <h5 className="text-white">Reset Password</h5>
                            <p className="text-white">Reset your password here</p>
                            <span className="fa fa-lock text-white"></span>
                        </div>
                        <div className="px-3 br-15 py-5 bg-white rightcont">
                            <p className="pt-4">Enter User Name</p>
                                <input type="text" 
                                required
                                className="form-control"
                                onChange={(e) => { setEmail(e.target.value) }}/>
                                <span className="text-danger f-12 mb-3 d-block text-left">{errors.email}</span>
                            <button className="btn btn-theme mt-4"onClick={()=>onSubmit()}>Request a Reset link</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default ForgotPwd