import React, { useState } from "react";

import { Modal} from "react-bootstrap";

import profimg from '../../Assets/Images/profile.png'
import PayStatus from "./PaymentStatusModal";

const TransferModal = (props) =>{
    const[transfer , setTransfer] = useState(true)
    const[paystatus , setPayStatus] = useState(false)

    const [first,showfirst] = useState(true)

    const showStatus = () =>{
        setPayStatus(true)
    }


    return(
        <div className="signinmodal">
            {paystatus && <PayStatus onDismisspay={() => {setPayStatus(false);showfirst(true);props.onDismiss()}} />}
            <Modal centered show={transfer} id="transfer" size="md" className={first?"br-15  thememodal editprofmodal selectdoc":"opa_zero br-15  thememodal editprofmodal selectdoc"}>
             
                <Modal.Body className="p-0 br-15 text-center  ">
                <button onClick={() =>props.onDismiss()} className="close-btn">X</button>
                 
                    <div className="bgblue text-white profileimg pb-3">
                        <div className="">
                             <img src={profimg} />
                             <p className="mb-0">Lorem ipsum</p>
                             <p className="mb-0">283.687TMC</p>

                             <h2>258.1576TMC</h2>
                        </div>
                      
                    </div>
                    <div className="balance py-4">
                        <p>Available balance: <span>580.48964TMC</span></p>
                        <button className="btn btn-theme" onClick={() => {showStatus();showfirst(false)}}>Confirm</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default TransferModal