
import React, { useState ,useEffect} from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { Modal,InputGroup, Form ,Button} from "react-bootstrap";
import{getPlanInfo,sleep,getTokeninfo, UsergetInfobyname} from "../../Hooks/UseBackendAction/UseGetController"
import {UsecreateUser} from "../../Hooks/UseBackendAction/UseCreateUser.js"
import { MetamaskWallet, WalletConnect } from "../../Hooks/UseWallet";
import { transferBNB, transferToken, userBalance } from "../../Hooks/UseWeb3";
import { ADMIN, BUSD, USDT } from "../../config/env";



const Web3Modal = (props) =>{
    const[buy , setBuy] = useState(true)
    const [PlanInfo, setPlanInfo] = useState([])
    const [userName, setUserName] = useState(props.registerinfo.user_name)
    const [FirstName, setFirstName] = useState(props.registerinfo.first_name)
    const [LastName, setLastName] = useState(props.registerinfo.last_name)
    const [Plan, setPlan] = useState()
    const [Email, setEmail] = useState(props.registerinfo.email)
    const [MobileNo, setMobileNo] = useState(props.registerinfo.mobile_no)
    const [Password, setPassword] = useState("")
    const [ReferrerID, setReferrerID] = useState(props.registerinfo.referrerID)
    const [imagepath, set_imagepath] = useState(props.registerinfo.logoURI)
    const [file, set_file] = useState({})
    const [ImageURL, setImageURL] = useState("")
    const dispatch = useDispatch()
    const[Token,setToken] = useState("")
    const [tokeninfo,setTokeninfo] = useState([])
    const [passwordeye, setpasswordeye] = useState(false)
    const [account,setAccount] = useState(localStorage.getItem("accountInfo"));
    const [amt,SetAmt] = useState("0")
    const [userInfo, setuserinfo] = useState({}) 
    const [balance,setbalance] = useState(0)


    useEffect(() => {
        getplanData();
        
    }, [])

    const getplanData = async () => {
        var plandata = await getPlanInfo();
        setPlanInfo(plandata.data)
        setPlan(plandata.data[0].name)
        const tokeninfo = await getTokeninfo();
        // setToken(tokeninfo.data[0].symbol)
        setTokeninfo(tokeninfo.data)
        var UserInfo  =    await UsergetInfobyname(localStorage.getItem("username"));
        console.log("log",UserInfo);
        setuserinfo(UserInfo?.data)
    };

    const Buy = async (data) =>{

        dispatch(UsecreateUser(data))
        await sleep(1000)
        // if (localStorage.getItem("iscreate")) {
           
        //     setBuy(false)
        //     window.location.reload()
        // }

    }



    const showPassword = (e) => {

        var e = document.getElementById("Password");
        if (e.type === "password") {
            setpasswordeye(true)
            e.type = "text";
        } else {
            setpasswordeye(false)
            e.type = "password";
        }
    };

    const onMeta = async()=>{
        try{
            await MetamaskWallet();
            setAccount(localStorage.getItem("accountInfo"))
           
            // if(props.registerinfo.address != localStorage.getItem("accountInfo")  && userInfo.address != localStorage.getItem("accountInfo")){
            //     toast.error(`Register Wallet Address mismatch !`,
            //     {
            //         style: {
            //         minWidth: '300px',
            //         minHeight: '55px'
            //         }
            //     });
            //     setAccount(null); localStorage.removeItem("accountInfo")
            // }
        }catch(e){
            setAccount(null);
        }
    }

    const onWC = async()=>{
        try{
            await WalletConnect();
            setAccount(localStorage.getItem("accountInfo"))
            if(props.registerinfo.address != localStorage.getItem("accountInfo")){
                toast.error(`Register Wallet Address mismatch !`,
                {
                    style: {
                    minWidth: '300px',
                    minHeight: '55px'
                    }
                });
                setAccount(null); localStorage.removeItem("accountInfo")
            }
        }catch(e){
            setAccount(null);
        }
    }

const getuserBalance = async (value) =>{
if(value == "BUSD"){
    var balance = await userBalance(BUSD);
    setbalance(balance)
}else if(value == "USDT"){
    var balance = await userBalance(USDT);
    setbalance(balance)
}

    console.log("balance",balance);

}

    const onSubmit = async () => {
        if(Token == "BUSD"){
            await transferToken(BUSD,ADMIN,amt);
        }else if(Token == "USDT"){
            await transferToken(USDT,ADMIN,amt);
        }
           
     };


    return(
        <div className="signinmodal ">
            <Modal id="isbuymodal" show={buy} size="md" centered className="br-15 thememodal  ">
             
                <Modal.Body className="p-0 br-15 ">
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>


                    <div className=" br-15 text-white  p-3 small-input noshadow">
                    <div className="px-4 text-center mb-4">
                                <h4 className="text-dark fw-600">Deposit</h4>
                               
                            </div>
                       
                          
                            <div className=" me-3 w-full my-4">
                              <label className="text-dark f-14">My Account</label>
 </div>
 {  account==null ?  (<div className="text-center"> <button className="btn btn-theme my-2 mx-3" onClick={()=>onMeta()}>MetaMask</button>  <button className="btn btn-theme my-2 mx-3" onClick={()=>onWC()}>WalletConnect</button> </div>) : <><label className="text-dark f-14">{ ` ${account.substring(0,4)}...${account.substring(37,42)}`}</label> <button className="btn btn-theme my-2 mx-3 text-white f-14"onClick={()=>{ setAccount(null); localStorage.removeItem("accountInfo") } } > logout</button> </>}
                             
                           
                           <div className='me-3 w-full my-4'>
                           <label className="text-dark f-14">Currency</label>
                                                <Form.Select aria-label="Default select example" 
                                                required
                                                value={Token}
                                                onChange={(e) => { setToken(e.target.value);getuserBalance(e.target.value)} }>
                                                     <option>Select Currency</option>
                                                        {/* {tokeninfo && tokeninfo.map((item) => 
                                                
                                                 <option value={item.symbol}>{item.name}</option>
                                            )} */}
                                             <option value={tokeninfo[0]?.symbol}>{tokeninfo[0]?.symbol}</option>
                                            <option value={tokeninfo[2]?.symbol}>{tokeninfo[2]?.symbol}</option>
                                                    
                                                </Form.Select>
                                                <p className="text-dark f-12 text-right">Balance : {parseFloat(balance.toFixed(3))} {Token}</p>
                                            </div>
                                            <div className=" me-3 w-full my-4">
                                            <Form.Control 
                                        required
                                        value={amt}
                                        onChange={(e) => { SetAmt(e.target.value) }} placeholder='0.0' aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        />
                            </div>
                 
<b><span className="text-black">Note : </span></b>
<ul className="f-14 mb-0 fw-400 text-black">
    <li>* Activation charges of 1% will be collected</li>
    <li >* For Prime Plan, deposit 101 USDT/BUSD</li>
     <li >* For Basic Plan, deposit 25.25 USDT/BUSD</li>
</ul>
                           <div className="text-end">
                             <button className="btn btn-theme my-3 " onClick={()=>onSubmit()}>Deposit</button></div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default Web3Modal