
import './App.css';
import { useEffect } from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Land from '../src/Components/Land';
import Home from '../src/Components/Home'
// import Asset from '../src/Components/Pages/AssetDetails'
import toast, { Toaster } from 'react-hot-toast';
import AdminLand from './Components/Admin/AdminLand';
import Login from './Components/Admin/AdminLogin';
import Transaction from './Components/Admin/ViewTransaction'
import loader from './Assets/Images/loader.svg'
import Contactus from './Components/Contactus';
function App() {

  
  return (
    <div className="App">
          <div id='loader'>
            <img src={loader} />
        </div>
      <div><Toaster /></div> 
       <BrowserRouter>
       <Routes>
           <Route path="/dashboard"  element={<Land /> } />
           <Route path="/"  element={<Home /> } />
           <Route path="/home"  element={<Home /> } />

           <Route path="/reset/:otp"  element={<Home /> } />
           {/* <Route path ="/asset" element={<Asset/>} /> */}
           <Route exact path="/:id" element={<Home />} />
           <Route path="/admin" element={<AdminLand />} />
           <Route path="/admin/login" element={<Login />} />
           <Route path="/usertransaction/:id" element={<Transaction />} />
           <Route path={"/contactus"} element ={<Contactus />} />
           
       </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
