import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal, Button, InputGroup, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import profimg from '../../Assets/Images/profile.png'
import { UseNewRegister, UseloginUser } from "../../Hooks/UseBackendAction/UseCreateUser.js"
import { getPlanInfo,UserreferrergetInfo,sleep,getsettinginfo } from "../../Hooks/UseBackendAction/UseGetController"
import { validateUserRegister } from "../../Hooks/UseValidation"
import OtpModal from "./OtpModal";
import {superAdminId,backurl,TENMINUTESINMILISECONDS} from "../../config/env";
import GoogleAuth from '../Modals/GoogleAuthModal';
import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from '../../Hooks/UseToast'


const SignModal = (props) => {
    const[otp , setOtp] = useState(false)
    const[googleAuth, setGoogleAuth] = useState(false)
    const [signin, setSignin] = useState(true)
    const [login, setLogin] = useState(true)
    const [register, setRegister] = useState(true)
    const [condition, setCondition] = useState(false)
    const [userName, setUserName] = useState("")
    const [FirstName, setFirstName] = useState("")
    const [LastName, setLastName] = useState("")
    const [Plan, setPlan] = useState()
    const [Email, setEmail] = useState()
    const [MobileNo, setMobileNo] = useState("")
    const [Password, setPassword] = useState()
    const [LoginEmail, setLoginEmail] = useState()
    const [LoginUsername, setLoginUsername] = useState()
    const [LoginPassword, setLoginPassword] = useState()
    const [Confirmpassword, setConfirmpassword] = useState("")
    const [ReferrerID, setReferrerID] = useState()
    const [walletaddress, setwalletaddress] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [PlanInfo, setPlanInfo] = useState([])
    const [userInfo, setuserInfo] = useState({})
    const [imagepath, set_imagepath] = useState("")
    const [file, set_file] = useState({})
    const [ImageURL, setImageURL] = useState("")
    const Reduxstate = useSelector(state => state.reducer)
    const [errors, seterrors] = useState({})
    const [imagesize, setimagesize] = useState(0)
   

    useEffect(() => {
      
        (window.innerWidth < 992 && condition == false) ? setRegister(false) : (window.innerWidth < 992 && condition == true && register == true) ? setRegister(true) : setRegister(true)
        // (window.innerWidth < 991 && condition == false && login == true ) ? setLogin(true) : (window.innerWidth < 991 && condition == true ) ? setLogin(false) : setLogin(true)
        getplanData();
        GetSuperadmin()
    }, [])

    const GetSuperadmin = async()=>{
        const AdminId = superAdminId;
        console.log("AdminId",AdminId);
        const referrerId = window.location.pathname.split("/")[1];
        var GetId = referrerId?referrerId:AdminId;
        setReferrerID(GetId)
        var UserInfo  =  await UserreferrergetInfo(GetId)
                         setuserInfo(UserInfo.data)
        
    }

   
    const getplanData = async () => {
        var plandata = await getPlanInfo();
        setPlanInfo(plandata.data)
        var settingdata = await getsettinginfo();
        setimagesize(Number(settingdata.data[10].settingvalue)) // image upload
        

    };


    const ImageUpload = event => {
        var name = event.target.value;
        var size = Number(event.target.files[0].size);
        if(size > imagesize  * 1000){
            toast.error(`Please select image size less than ${imagesize} KB`, {
                position:position.position,
                style:style,
                iconTheme: iconTheme,
            })
            
        }else{
            set_imagepath(name);
            set_file(event.target.files[0]);
        var url = URL.createObjectURL(event.target.files[0])
        setImageURL(url)
        }
    };

    const userLogin = async(data) =>{

        dispatch(UseloginUser(data))
        await sleep(1000)
        if ((sessionStorage.getItem("username") || localStorage.getItem("username")) && (sessionStorage.getItem("isauth") === "false" )) {
            document.getElementById("signmodal").style.display = "none";
            // setOtp(true)
        }else if(sessionStorage.getItem("isauth") === "true" ){
            document.getElementById("signmodal").style.display = "none";
            setGoogleAuth(true)

        }

    }

    const onLoginSubmit = async (e) => {

        e.preventDefault();
        const UserLoginData = {
            // email: LoginEmail,
            user_name:LoginUsername,
            password: LoginPassword,
            // CreatedAt: Date.now() + TENMINUTESINMILISECONDS

        };
        
        var data =  await userLogin(UserLoginData);
        
    };

    const UserRegister =async(data) =>{
        dispatch(UseNewRegister(data))
        await sleep(1000)
       
        // if(localStorage.getItem("isRegister") ==='true')
        // {
        // setSignin(false)
        // }
    }

    const onRegisterSubmit = async (e) => {

        e.preventDefault();
        const UserValidateData = {
            user_name: userName.trim(),
            first_name: FirstName,
            last_name: LastName,
            email: Email,
            mobile_no: MobileNo,
            password: Password,
            confirmpassword: Confirmpassword,
            
        };
        // console.log("UserValidateData",UserValidateData.user_name);
        const UserData = {
            user_name: userName,
            first_name: FirstName,
            last_name: LastName,
            email: Email,
            mobile_no: MobileNo,
            password: Password,
            confirmpassword: Confirmpassword,
            referrerID: ReferrerID,
            file: file!={} ? file : imagepath,
            address:walletaddress.toLocaleLowerCase(),

        };
        
        const { errors, isValid } = await validateUserRegister(UserValidateData);
        if (!isValid ) {
            console.log("if");
            seterrors(errors)
        }else {
            console.log("else");
           await UserRegister(UserData)
           
        }

        //   setTimeout(() => {
        //     dispatch(UsecreateUser(UserData))
        // },1000);
        // if(isRegister) {

        //     setSignin(false)
        // }
       
        // if (Reduxstate.user?.data?.success && Reduxstate.user?.data?.success === true) {
        //     navigate("/dashboard")
        // }

    };

    const showPassword = (e) => {

        var e = document.getElementById("password");
        if (e.type === "password") {
            e.type = "text";
        } else {
            e.type = "password";
        }
    }
    const LoginshowPassword = (e) => {

        var e = document.getElementById("Loginpassword");
        if (e.type === "password") {
            e.type = "text";
        } else {
            e.type = "password";
        }
    }

    const ConfirmshowPassword = (e) => {

        var e = document.getElementById("Confirmpassword");
        if (e.type === "password") {
            e.type = "text";
        } else {
            e.type = "password";
        }
    }
    const showotp = () =>{
        document.getElementById("signmodal").style.display = "none";
        setOtp(true)

    }
    
const openforgotmodal = () =>{
    props.onDismiss()
    props.openmodal()
}

    return (
    
        <div className="signinmodal editprofile">
              
{otp && <OtpModal onDismiss={() => {setOtp(false); props.onDismiss()}} />}
{googleAuth && <GoogleAuth onDismiss={() => setGoogleAuth(false)} />}
            <Modal centered show={signin} size="xl" className="br-15 thememodal"  id="signmodal">

                <Modal.Body className="p-0 br-15">
                    <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <div className="d-flex bgblue align-items-center br-15 editprof">
                        <form onSubmit={onLoginSubmit} className={login ? "signin py-lg-5 pb-0 pt-5 w-35 h-44 small-input" : "signin py-lg-5 pb-0 pt-5 w-35 h-44 small-input d-none"}>
                            <div className="px-4">
                                <h4 className="themeclr fw-600">Login</h4>
                                <p>To continue, sign in to TMC</p>
                            </div>
                            <hr className="themehr" />
                            <div className=" p-4 themebg">
                            <div className="mb-3 me-3 w-full">
                                    <Form.Label>User Name</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"

                                            aria-label="Username"
                                            placeholder="User Name"
                                            required
                                            value={LoginUsername}
                                            onChange={(e) => { setLoginUsername(e.target.value) }}
                                            aria-describedby="basic-addon1" />
                                       

                                    </InputGroup>
                                    {/* <span className="text-danger mb-3 d-block text-left">{errors.email}</span> */}
                                </div>
                                <div className="mb-3 me-3 w-full">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="password"

                                            aria-label="Username"
                                            placeholder="Password"
                                            required
                                            value={LoginPassword}
                                            id="Loginpassword"
                                            onChange={(e) => { setLoginPassword(e.target.value) }}
                                            aria-describedby="basic-addon1" />
                                        <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={LoginshowPassword}></Button>
                                    </InputGroup>
                                    {/* <span className="text-danger f-12 mb-3 d-block text-left">{errors.password}</span> */}
                                </div>

                                <Button variant="link" className="txtdarkblue ps-0 f-14 fw-500" onClick={()=> openforgotmodal()}>Forgot Password?</Button>
                                <div className="text-end">
                                    <button className="btn btn-theme  mt-4" >Login</button>
                                </div>

                            </div>

                        </form>

                        <form onSubmit={onRegisterSubmit} className={register ? "signup bg-white pt-5 w-full  small-input noshadow" : "signup bg-white pt-5 w-full h-44 small-input noshadow d-none"}>
                            <div className="px-4">
                                <h4 className="themeclr fw-600">Register</h4>
                                <p>New to TMC ?, Register here.</p>
                            </div>

                            <hr className="themehr" />


                            <div className=" p-4">
                                <div className="editimg mb-4">
                                    {ImageURL && ImageURL.length > 0 ?
                                        <img src={ImageURL} /> : <img src ={profimg} />
                                    }
                                    <div className="fileupload">
                                        <span className="fa fa-camera" ></span>
                                        <input type="file"
                                        // required
                                            accept=".jpg,.jpeg,.png"
                                            onChange={ImageUpload}
                                            value={imagepath} />
                                    </div>

                                </div>

                                <div className="d-lg-flex jc-between h-44">

                                    {/* <div className="mb-3 me-3 w-full">
                                        <Form.Label>First Name</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                // required
                                                value={FirstName}
                                                onChange={(e) => { setFirstName(e.target.value) }}
                                                placeholder="First Name"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />
                                        </InputGroup>
                                    </div> */}
                                    {/* <div className="mb-3 me-3 w-full">
                                        <Form.Label>Last Name</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text" placeholder="Last Name"
                                                // required
                                                value={LastName}
                                                onChange={(e) => { setLastName(e.target.value) }}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />


                                        </InputGroup>
                                    </div> */}
                                </div>
                                <div className="d-lg-flex jc-between h-44">
                                <div className="mb-3 me-3 w-full">
                                <span className="text-danger">* </span>
                                        <Form.Label>Wallet Address </Form.Label>
                                        <a target="blank" className="ps-3 f-12" href="https://docs.trademaxcoin.com">Click here for help?</a>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Wallet address" required
                                                value={walletaddress}
                                                onChange={(e) => { setwalletaddress(e.target.value) }}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />
                                        </InputGroup>
                                       
                                    </div>
                                    <div className="mb-3 me-3 w-full">
                                    <span className="text-danger">* </span>
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Email" required
                                                value={Email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />
                                        </InputGroup>
                                        <span className="text-danger f-12 mb-3 d-block text-left">{errors.email}</span>
                                    </div>
                                </div>
                                <div className="d-lg-flex jc-between h-44">
                                    <div className="mb-3 me-3 w-full">
                                    <span className="text-danger">* </span>
                                        <Form.Label>User Name</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text" placeholder="User Name"
                                                required
                                                value={userName}
                                                pattern="^[a-z0-9_\-]+$"
                                                title="Only Accept Lower Case"
                                                onChange={(e) => { setUserName(e.target.value) }}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />
                                        </InputGroup>
                                        {/* <span className="text-danger f-12 mb-3 d-block text-left">{errors.user_name}</span> */}
                                    </div>
                                    {/* <div className="mb-3 me-3 w-full">
                                        <Form.Label>Mobile No</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text" 
                                                // required
                                                value={MobileNo}
                                                placeholder="Mobile No"
                                                pattern="[1-9]{1}[0-9]{9}"
                                                title="Enter 10 digit Mobile Number"
                                                maxlength="10"
                                                onChange={(e) => { setMobileNo(e.target.value) }}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1" />


                                        </InputGroup>
                                        <span className="text-danger f-12 mb-3 d-block text-left">{errors.mobile_no}</span>
                                    </div> */}
                                </div>
                                <div className="d-lg-flex jc-between h-44">
                                    <div className="mb-3 me-3 w-full">
                                        <span className="text-danger">* </span>
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="password"

                                                aria-label="Username"
                                                placeholder="Password"
                                                required
                                                value={Password}
                                                id="password"
                                                onChange={(e) => { setPassword(e.target.value) }}
                                                aria-describedby="basic-addon1" />
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showPassword}></Button>

                                        </InputGroup>
                                        <span className="text-danger f-12 mb-3 d-block text-left">{errors.password}</span>
                                    </div>
                                    <div className="mb-3 me-3 w-full">
                                    <span className="text-danger">* </span>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="password"
                                                placeholder="Confirm Password"
                                                aria-label="Username"
                                                required
                                                id="Confirmpassword"
                                                value={Confirmpassword}
                                                onChange={(e) => { setConfirmpassword(e.target.value) }}
                                                aria-describedby="basic-addon1" />
                                            <Button variant="outline-secondary" id="button-addon2" className="fa fa-eye inputbtn" onClick={ConfirmshowPassword}></Button>

                                        </InputGroup>
                                        <span className="text-danger f-12 mb-3 d-block text-left">{errors.confirmpassword}</span>
                                    </div>



                                </div>

                                <h5 className="themeclr fw-600 mt-3 mb-4">Referred by</h5>

                                <div className="d-lg-flex graybox jc-between gap-5 p-4">
                                    <div className="editimg mb-4">
                                        <img src={backurl+`/Images/${userInfo.logoURI}` } onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src=profimg;
  }}/>
                                    </div>

  

                                    <Form.Group className="mb-3 me-3 w-full" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Referred ID</Form.Label>
                                        <Form.Control
                                            required
                                            // readOnly
                                            type="text"
                                            value={ReferrerID}
                                            onChange={(e) => { setReferrerID(e.target.value) }}
                                            placeholder="Referred ID" />
                                        <Form.Label>
                                            <span className="text-danger f-12 mb-3 d-block text-left">If you don't have Referred Id,Directly Comes Under TMC</span>
                                        </Form.Label>
                                    </Form.Group>
                                </div>



                                <div className="text-end mt-4">
                                    <button className="btn btn-theme">Register</button>
                                </div>
                            </div>

                        </form>
                    </div>
                    {login ? <Button variant="link" className="txtdarkblue blue showin991 editprofile ps-0 f-14 fw-500" onClick={() => { setRegister(true); setLogin(false); setCondition(true) }}>If you don't have an account?</Button> :
                        <Button variant="link" className="txtdarkblue white showin991 editprofile ps-0 f-14 fw-500" onClick={() => { setRegister(false); setLogin(true); setCondition(true) }}>Already have an account?</Button>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default SignModal