import React, { useState } from 'react';
import Header from './Header';


import Sidebar from './Sidebar';

import Dashboard from './Pages/Dashboard';
import AssetDetails from './Pages/AssetDetails';
import TwoFactorAuth from './Pages/TwoFactorAuth';
import TwoFactorAuth2 from './Pages/TwoFactorAuth2';
import Income from './Pages/Income';
import Transactions from './Pages/Transactions';
import Exchange from './Pages/Exchange';


const Land = (props) => {
    const[data, setData] =useState(false)
    const[option, setOption] = useState(0)
    const newfunc = () =>{
        setData(!data)
    }
    const Menu1 = (data) =>{
        setOption(data)
    }
    
    return(
        <>
        <Header newfunc={() =>newfunc()}/>
        <div id='page'>
            <Sidebar data = {data} Menu={Menu1} newfunc={newfunc}/>
            
            <div id="cont" className= {data ? "expand pt-5" : "compress pt-5"}>

                {option == 0 ? <Dashboard Menu={Menu1} /> : option == 1 ?<AssetDetails /> : option == 2 ? <TwoFactorAuth /> : option == 3 ? <TwoFactorAuth2 /> :  option == 4 ? <Income/> : option == 5 ? <Transactions /> : <Exchange />}

            </div>


        </div>
        </>
    )
}

export default Land 