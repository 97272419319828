import React,{useState,useEffect} from "react";
import DataTable from 'react-data-table-component'
import {InputGroup, Form} from 'react-bootstrap'
import AddSetting from "./Modals/AddSettingModal";
import UpdateSetting from  "./Modals/UpdatesettingModal"
import {getsettinginfo} from "../../Hooks/UseBackendAction/UseGetController"
const Settings = () =>{
   
   
    const[addusers, setAddUsers] = useState(false)
    const[updateSettingModal, setupdateSetting] = useState(false)
    const [records, setRecords] = useState([])
    const [Currentrecords, setCurrentRecords] = useState({})

    useEffect(() => {
      getData();
  
  }, [])
  
  const getData = async () => {
    var data = await getsettinginfo();
    setRecords(data.data)
  };
    const columns = [
      {
        name: "S.No",
        selector: (data,index) => index+1,
        sortable: true
      },
      {
          name: "Setting Name",
          selector: (data) => data.settingname,
          sortable: true
        },
        {
          name: "Value",
          selector: (data) => data.settingvalue,
          sortable: true
        },
        {
          name: "Edit",
          button: true,
          cell: (data) => (
       
        <button
          type="button"
          class="btn btn-theme"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          onClick={() => { editRecord(data); setupdateSetting(true) }}>
          Edit
        </button>
        )
        }
     
  ]

  const editRecord = async (record) => {
    setCurrentRecords(record)
}
    return(
        <div className="viewuser">
            {addusers && <AddSetting onDismiss={() => setAddUsers(false)} />}
            {updateSettingModal && <UpdateSetting record={Currentrecords} onDismiss={() => setupdateSetting(false)} />}
              <div className='container container-theme'>
                <div className="d-flex jc-between small-input noshadow">
                    <h4 className="flex-shrink-0 mb-0">Settings</h4>
                   <button className="btn btn-theme" onClick={() => setAddUsers(true)}>Add</button>
                </div>
                <div className="mt-4 viewdata">
                    <DataTable pagination columns={columns} data={records}/>
                </div>
                
                </div>
        </div>
    )
}

export default Settings