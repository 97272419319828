import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {DropdownButton ,Dropdown} from 'react-bootstrap'
import dashboard from '../Assets/Images/dashboard.png'
import portfolio from '../Assets/Images/portfolio.png'
import account from '../Assets/Images/account.png'
import exchange from '../Assets/Images/bag.svg'
import settings from '../Assets/Images/settings.png'
import history from '../Assets/Images/history.png'
import income from '../Assets/Images/income.png'
// import icon5 from '../Assets/Images/history.png'
import icon6 from '../Assets/Images/history.png'
import { MenuList } from '@material-ui/core';
import Scrollbars from 'react-custom-scrollbars';

const Sidebar = (props) =>{
    const[active, setActive] = useState(0)

    const Menus = (data) =>{
        props.Menu(data)
        setActive(data)
    } 
//   const img1 =  <img src={icon2} /> 


 const mobilemenu = (data) =>{
    const classes = document.getElementById("sidebar").classList
    const contclasses = document.getElementById("cont").classList
    if((window.innerWidth < 576 )){
        props.newfunc()
    }

    return ( (window.innerWidth < 576 ) ? <>{classes.add("expand")}{classes.remove("compress")}</> : 
   "",

    (window.innerWidth < 576 ) ? <>{contclasses.add("compress")}{contclasses.remove("expand")}</> : 
   ""
    )


    

 }
    return(
        <div id="sidebar" className={!props.data ? "expand" : "compress"}>
            <Scrollbars className='sidebar-scroll'>
            <ul>
                <li onClick={() => {Menus(0);  mobilemenu()}} className={active == 0 ? "active" : ""}>
                     <img src={dashboard} /> <span>Dashboard</span>
                </li>
                <li onClick={() => {Menus(1);  mobilemenu()}}>
                    <div  className='d-flex'> <img src={portfolio} />
           <span>My Portfolio</span>

</div>
 

          


                </li>
                <li onClick={() => { Menus(2);  mobilemenu()}}  className={active == 2 ? "active" : ""}>
                     <img src={account} /> <span>Account</span>
                </li>
                <li onClick={() =>  {Menus(3);  mobilemenu()}}  className={active == 3 ? "active" : ""}>
                     <img src={settings} /> <span>Settings</span>
                </li>
                <li onClick={() =>  {Menus(4);  mobilemenu()}}>
                     <img src={income} /> <span>Income</span>
                </li>
                <li onClick={() =>  {Menus(5);  mobilemenu()}}>
                     <img src={history} /> <span>History</span>
                </li>
                <li onClick={() =>  {Menus(6);  mobilemenu()}}  className={active == 3 ? "active" : ""}>
                     <img src={exchange} /> <span>Exchange</span>
                </li>
            </ul>
            </Scrollbars>
        </div>
    )
}

export default Sidebar