import React, { useState,useEffect } from "react";
import { Modal,Form } from "react-bootstrap";
import{getTokeninfo} from "../../Hooks/UseBackendAction/UseGetController"
import { UseClaim} from "../../Hooks/UseBackendAction/UseTranfer";

const Claim = (props) =>{
    
    const [claim , setClaim] = useState(true)
    const [tokeninfo,setTokeninfo] = useState([])
    const[Token,setToken] = useState("")
    const[UserId , setUserId] = useState(sessionStorage.getItem("userId"))
    const[isClaim,setisClaim] = useState(false)

    useEffect(()=>{
        if((parseFloat(props?.record?.userInfo?.gainAmount) - parseFloat(props?.record?.userInfo?.lastClaimedGain))> 0){
            setisClaim(true)
           }

        gettokeninfo(); 
        
    
    },[isClaim])
    
    const gettokeninfo = async () =>{
        
        const tokeninfo = await getTokeninfo();
        setTokeninfo(tokeninfo.data)
        setToken(tokeninfo.data[0]?.symbol)
        
    }

    const onSubmit = async () => {
         const UserClaimData = { 
            userId:UserId,
            type: Token === "USDT" ? 0 : 2,
            token:Token
        };
         await UseClaim(UserClaimData)
           
     };
  
    return(
        <div className="signinmodal ">
            <Modal show={claim} centered size="md" className="thememodal paystatus ">
              <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                <Modal.Body className="p-0">
                    <div className="d-flex flex-1">
                        <div className="text-center leftcont px-3 py-5 bgblue" style={{borderRadius:"15px 0 0 15px"}}>
                            <h5 className="text-white">Claim</h5>
                            <p className="text-white">Your Claimable Amount  {(parseFloat((props?.record?.userInfo?.gainAmount) - parseFloat(props?.record?.userInfo?.lastClaimedGain))/10 ** 18).toFixed(5)} </p>
                            <span className="fa fa-usd text-white mb-0"></span>
                        </div>
                        <div className="px-3 br-15 py-5 bg-white rightcont">
                                
                                <p className="pt-4">Claim In</p>
                                <Form.Select 
                                value={Token}
                                onChange={(e) => {setToken(e.target.value) ;console.log("Token",e.target.value);}}>
                                    
                                      <option value={tokeninfo && tokeninfo[0]?.symbol}> {tokeninfo[0]?.name}</option>
                                      <option value={tokeninfo && tokeninfo[2]?.symbol}> {tokeninfo[2]?.name}</option>
                                    
                                </Form.Select>
                                <div className="text-end mt-4">
                                {isClaim ?
                                    <button className="btn btn-theme mt-4" onClick={()=> onSubmit()}>Claim</button>
                                     : 
                                     <button disabled className="btn btn-theme mt-4 yellowbtn">Claim</button>
                                      }
                                 
                                </div>
                                
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default Claim