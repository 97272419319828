import React,{useState,useEffect} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from 'react-hot-toast';
import coin3 from '../../Assets/Images/coin3.png'
import tether from '../../Assets/Images/tether.png'
import laptick from '../../Assets/Images/lap-tick.png'
import busd from '../../Assets/Images/busd.png'
import logobg from '../../Assets/Images/logobg.png'
import BuyModal from '../Modals/BuyModal';
import UpgradeModal from '../Modals/Upgradeplan';
import * as GetController from "../../Hooks/UseBackendAction/UseGetController"
import { Button } from 'react-bootstrap';
import {style,iconTheme,position} from '../../Hooks/UseToast'
import {backurl} from "../../config/env";



    


const Transactions = () => {
    const [buy , setBuy] = useState(false)
    const [Upgrade , setUpgrade] = useState(false)
    const [tokenAddress , settokenAddress] = useState("")
    const [UserName , setUserName] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    const [RegisterIngo,setRegisterIngo] = useState({})
    const [isuserCreate,setisuserCreate] = useState(false)
    const[coindata,setcoindata] = useState([])
    const [userid, setuserid] = useState(sessionStorage.getItem("userId"))

     
    useEffect(() => {
        setUserName()
        getdepositData();
       
       
    }, [])
    
    const UseTransactions = ()=>{
        return (  coindata && coindata.map((item) => (item.flow !== "SWAP" ? <>    
                     <li className={`graybox br-10 p-2 ${item.flow === "IN" || (item.to === userid)  ? "in" : "out"}`} > 
                       <div className='row'>
                           <div className='col-md-4 col-sm-12 mb-md-0 mb-3'>
                           <div className='d-flex'> 
                                <img src={coin3} />
                                   <div>
                                   <p className='mb-0 currency'> <b>{(item.amount / 10  ** 18).toFixed(5)} {item.symbol || "USD"}</b></p>
                                   <p className='mb-0 f-14'>{new Date(parseInt(item.createdAt)).toLocaleString("es-US")}</p>
                                   </div>
                               </div>
                           </div>
                           <div className='col-md-4 col-6'>
                               <p className='mb-0'>From</p>
                               <p className='mb-0'><b>{item.from.length > 14 ? item.from.substring(0,14)+"..." : item.from}</b></p>
                           </div>
                           
                           <div className='col-md-4  col-6'>
                           <div>
                               <p className='mb-0'>To</p>
                               <p className='mb-0'><b>{item.from.length > 14 ? item.to.substring(0,14)+"..." : item.to}</b></p>
                           </div>
                           </div>
                       </div>
                       
                   </li>
                    
                   </> : <></> )));              
             
        
    }
    
    const getdepositData = async () => {
       
        var UserInfo  =    await GetController.UsergetInfobyname(UserName);
        var TransactionInfo = await GetController.UserTransactions(UserInfo?.data?.userInfo.userId);
        
        if(UserInfo?.success === true){
            setisuserCreate(true)
            
        
           
    
    
        setcoindata(TransactionInfo.data);
        }else{
            document.getElementById("balance").classList.add("blur")
        }
        var deposittoken = await GetController.getdeposittoken();
        var RegisterIngo = await GetController.UsergetRegisterInfo(UserName)
       
                                 setRegisterIngo(RegisterIngo?.data)
        
        
        settokenAddress(deposittoken?.data)
       
};


    return(
        <>
        {buy && <BuyModal registerinfo={RegisterIngo} onDismiss={() => setBuy(false)} />}
        {Upgrade && <UpgradeModal  onDismiss={() => setUpgrade(false)} />}

        
         <div className='container container-theme logocont'>
            <img src={logobg} className="logobg"/>
    
 
                    <div className='row mb-12 history justify-content-center'>
                   
                        <div className='col-xl-8 col-lg-12'>
                            <div className='whitebox  mb-4' id="balance">
                            <div className="historyscroll">
                        <h5 className='fw-400 bluetxt mb-5'>User <span className='fw-700'>Transactions</span></h5>
                           
                            <div className='d-flex jc-between '>
                            {/* <p className='fw-400 bluetxt mb-0'>Wallet <span className='fw-700'>Balance</span></p>
                            <button className='btn btn-theme' >Refresh</button> */}
                            </div>
                            <div className=''>
                                <ul>
                              {  UseTransactions() }
                               </ul>
                           </div>

                           
                               
                              </div>
                            </div>
                           
                     
                           
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Transactions