import React, { useState } from "react";

import { Modal,InputGroup, Form ,Button} from "react-bootstrap";

import profimg from '../../Assets/Images/profile.png'

const PayStatus = (props) =>{
    const[paystatus , setPayStatus] = useState(true)
    return(
        <div className="signinmodal ">
            <Modal centered show={paystatus} size="sm" className="br-15 thememodal paystatus">
             
                <Modal.Body className="p-0 br-15 bgblue">
                <button onClick={() => props.onDismisspay()} className="close-btn">X</button>
                    <div className=" br-15 text-white text-center p-2">
                        <span className="fa  fa-check"></span> 
                        {/* fa-times  */}
                        <h3 className="fw-600"> Your payment was successfull</h3>
                        
                       <p className="f-14">Lorem ipsum is a dummy content Lorem  is a dummy content</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default PayStatus