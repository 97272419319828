import React, { useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Form, Button, InputGroup } from 'react-bootstrap'
import qr from '../../Assets/Images/qr.png'
import { ResetPassword, withdrawResetPassword, UseisAuth, GenerateOTP } from "../../Hooks/UseBackendAction/UseCreateUser.js";
import { validateUserPassword, validatewithdrawPassword } from "../../Hooks/UseValidation";
import { sleep, UsergetInfobyname } from '../../Hooks/UseBackendAction/UseGetController';
import ForgotPwd from "../Modals/WithdrawforgotModal"
import OtpModal from "../Modals/OtpModal";
import { TENMINUTESINMILISECONDS } from '../../config/env';

const TwoFactorAuth2 = (props) => {
    const[otp , setOtp] = useState(false)
    const [Currentpassword, setCurrentPasssword] = useState("")
    const [Newpassword, setNewpassword] = useState("")
    const [Confirmpassword, setConfirmPasssword] = useState("")
    const [withdrawCurrentPassword, setwithdrawCurrentPassword] = useState("")
    const [withdrawPassword, setwithdrawPassword] = useState("")
    const [withdrawconfirmpassword, setwithdrawconfirmpassword] = useState("")
    const [errors, seterrors] = useState({})
    const [isauth, setisauth] = useState(null);
    const [userName, setuserName] = useState(sessionStorage.getItem("username") || localStorage.getItem("username"))
    const [UserInfo, setUserInfo] = useState({})
    const [passwordeye, setpasswordeye] = useState(false)
    const [passwordneweye, setpasswordneweye] = useState(false)
    const [passwordconfirmeye, setpasswordconfirmeye] = useState(false)
    const [loginpassword, setloginpassword] = useState(false)
    const [withdrawsetpassword, setwithdrawsetpassword] = useState(false)
    const [confirmwithdraw, setconfirmwithdraw] = useState(false)
    const [forgotpwd, setForgotPwd] = useState(false)
    const [status,setstatus] = useState(false)

    useEffect(() => {

        getuserData();
    }, [UserInfo]);

    const getuserData = async () => {
        var UserInfo = await UsergetInfobyname(userName);
        if (UserInfo.success === true) {
            setUserInfo(UserInfo.data)
            setisauth(UserInfo?.data?.isAuth)
        }
    };

   


    const ChangePassword = async(data)=>{
        await GenerateOTP(data)
        
      await sleep(1000)  
          if (localStorage.getItem("isOTP") === "null") {
            setOtp(true)
            setstatus(true)
        }
    }


    const OPT = async()=>{

        const UserLoginData = {
            user_name:userName,
            to: "",
            CreatedAt: Date.now() + TENMINUTESINMILISECONDS,

        };
      await  ChangePassword(UserLoginData)
      
}

    const onSubmit = async () => {

        const UserpasswordData = {

            user_name: userName,
            currentpassword: Currentpassword,
            confirmpassword: Confirmpassword,
            password: Newpassword

        };
        const { errors, isValid } = await validateUserPassword(UserpasswordData);
        if (!isValid) {
            seterrors(errors)
        }
        else {
            await ResetPassword(UserpasswordData)
        }
    };

    const onWithdrawSubmit = async () => {

        const UserwithdrawpasswordData = {

            user_name: userName,
            password: withdrawCurrentPassword,
            withdrawPassword: withdrawPassword,
            withdrawconfirmpassword: withdrawconfirmpassword


        };
        const { errors, isValid } = await validatewithdrawPassword(UserwithdrawpasswordData);
        if (!isValid) {
            seterrors(errors)
        }
        else {
            await withdrawResetPassword(UserwithdrawpasswordData)
        }
    };



    const onisAuthSubmit = async () => {

        const isauthdata = { user_name: userName, isAuth: isauth };
        await UseisAuth(isauthdata)


    };

    const showPassword = (e) => {

        var e = document.getElementById("Password");
        if (e.type === "password") {
            setpasswordeye(true)
            e.type = "text";
        } else {
            setpasswordeye(false)
            e.type = "password";
        }
    };
    const showNewPassword = (e) => {

        var e = document.getElementById("newPassword");
        if (e.type === "password") {
            setpasswordneweye(true)
            e.type = "text";
        } else {
            setpasswordneweye(false)
            e.type = "password";
        }
    };

    const showConfirmPassword = (e) => {

        var e = document.getElementById("confirmPassword");
        if (e.type === "password") {
            setpasswordconfirmeye(true)
            e.type = "text";
        } else {
            setpasswordconfirmeye(false)
            e.type = "password";
        }
    }

    const onchangeCheck = () => {
        if (isauth == true) {
            setisauth(false)
        }
        else {
            setisauth(true)
        }
    }

    const showLoginPassword = (e) => {

        var e = document.getElementById("LoginPassword");
        if (e.type === "password") {
            setloginpassword(true)
            e.type = "text";
        } else {
            setloginpassword(false)
            e.type = "password";
        }
    };
    const showSetwithdrawPassword = (e) => {

        var e = document.getElementById("setwithdraw");
        if (e.type === "password") {
            setwithdrawsetpassword(true)
            e.type = "text";
        } else {
            setwithdrawsetpassword(false)
            e.type = "password";
        }
    };
    const showconfirmwithdrawPassword = (e) => {

        var e = document.getElementById("withdrawconfirm");
        if (e.type === "password") {
            setconfirmwithdraw(true)
            e.type = "text";
        } else {
            setconfirmwithdraw(false)
            e.type = "password";
        }
    };


    return (
        <>
         {otp && <OtpModal onDismiss={() => {setOtp(false)}} />}
            {forgotpwd && <ForgotPwd onDismiss={() => setForgotPwd(false)} />}
            <div className='container container-theme'>
                <h5 className='fw-400 bluetxt'><span className='fw-700'>Two Factor</span> Authentication </h5>
                <div className='row mb-5'>
                    <div className=' col-xl-7 col-lg-6'>
                        <div className='whitebox small-input noshadow'>
                            <Scrollbars style={{ height: 355 }} className="scanqr">
                                <div className='d-flex jc-between align-items-center'>
                                    <h5 className='fw-400 bluetxt mb-0'> Google  <span className='fw-700'>Authentication code</span> </h5>
                                    <Form.Check
                                        type="switch"
                                        id="codeswitch"
                                        checked={isauth}
                                        onChange={async () => { await onchangeCheck(); onisAuthSubmit() }}
                                    />


                                </div>

                                <hr />


                                {/* <div>
                                <h6 className='aqua fw-600 mt-5'></h6>
                                    <Form.Control placeholder="Enter Secret code"
                                                    aria-label="Username"/> */}
                                <div className='mt-4 d-flex jc-between'>
                                    <p className='mb-0'>Your Two Factor Authentication Now,</p>
                                    <button className='btn btn-theme me-2 upper'>{UserInfo.isAuth === true ? "Enabled" : "Disabled"}</button>

                                </div>


                                {/* </div> */}

                            </Scrollbars>
                        </div>
                        <div className='whitebox '>
                            <Scrollbars style={{ height: 120 }}>
                                <p className='boxhead graytxt upper'>Notes</p>
                                <p className='f-14 mb-0 fw-400'>
                                The login password and withdraw password should not be same.Use different passwords.

                                </p>
                            </Scrollbars>
                        </div>
                    </div>
                    <div className='col-xl-5 col-lg-6'>
                        <div className='whitebox small-input'>
                            <Scrollbars style={{ height: 530 }}>
                                <h5 className='fw-400 bluetxt'> Change <span className='fw-700'>Password</span> </h5>

                                <div className='px-4'>
                                    <label className='mt-4'>Current Password</label>
                                    <InputGroup className="mt-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            value={Currentpassword}
                                            type="password"
                                            id="Password"
                                            onChange={(e) => { setCurrentPasssword(e.target.value) }}
                                            placeholder="Current Password"
                                            aria-label="Username" />
                                        {!passwordeye ?
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showPassword}></Button> :
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showPassword}></Button>}
                                    </InputGroup>
                                    <label className='mt-4'>New Password</label>

                                    <InputGroup className="mt-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="password"
                                            id="newPassword"
                                            value={Newpassword}
                                            onChange={(e) => { setNewpassword(e.target.value) }}
                                            placeholder="New password"
                                            aria-label="Username" />
                                        {!passwordneweye ?
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showNewPassword}></Button> :
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showNewPassword}></Button>}
                                    </InputGroup>

                                    <span className="text-danger f-12 mb-3 d-block text-left">{errors.password}</span>
                                    <label className='mt-1'>Confirm New Password</label>
                                    <InputGroup className="mt-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="password"
                                            id="confirmPassword"
                                            placeholder="Confirm New password"
                                            value={Confirmpassword}
                                            onChange={(e) => { setConfirmPasssword(e.target.value) }}
                                            aria-label="Username"

                                        />
                                        {!passwordconfirmeye ?
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showConfirmPassword}></Button> :
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showConfirmPassword}></Button>}
                                    </InputGroup>
                                    <span className="text-danger f-12 mb-3 d-block text-left">{errors.confirmpassword}</span>
                                  {!status ?   
                                    <button className='btn btn-theme mt-3 upper' onClick={() => OPT()}>Sent OPT</button> :
                                    <button className='btn btn-theme mt-3 upper' onClick={() => onSubmit()}>Submit</button>}
                                </div>
                                <h5 className='fw-400 bluetxt mt-4'> Transaction <span className='fw-700'>Password</span> </h5>
                                <hr />
                                <div className='px-4'>

                                    <label className='mt-2'>Login Password</label>
                                    <InputGroup className="mt-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="password"
                                            id="LoginPassword"
                                            placeholder="Login Password"
                                            aria-label="Username"
                                            value={withdrawCurrentPassword}
                                            onChange={(e) => { setwithdrawCurrentPassword(e.target.value) }} />
                                        {!loginpassword ?
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showLoginPassword}></Button> :
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showLoginPassword}></Button>}
                                    </InputGroup>
                                    <label className='mt-2'>Set Withdraw Password</label>
                                    <InputGroup className="mt-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            placeholder="Withdraw Password"
                                            aria-label="Username"
                                            id="setwithdraw"
                                            type="password"
                                            value={withdrawPassword}
                                            onChange={(e) => { setwithdrawPassword(e.target.value) }} />
                                        {!withdrawsetpassword ?
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showSetwithdrawPassword}></Button> :
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showSetwithdrawPassword}></Button>}
                                    </InputGroup>
                                    <span className="text-danger f-12 mb-3 d-block text-left">{errors.withdrawPassword}</span>
                                    <label className='mt-2'>Confirm Withdraw Password</label>
                                    <InputGroup className="mt-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            placeholder="Confirm Withdraw Password"
                                            aria-label="Username"
                                            type="password"
                                            id="withdrawconfirm"
                                            value={withdrawconfirmpassword}
                                            onChange={(e) => { setwithdrawconfirmpassword(e.target.value) }} />
                                        {!confirmwithdraw ?
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye-slash inputbtn" onClick={showconfirmwithdrawPassword}></Button> :
                                            <Button variant="outline-secondary" id="button-addon" className="fa fa-eye inputbtn" onClick={showconfirmwithdrawPassword}></Button>}
                                    </InputGroup>
                                    <span className="text-danger f-12 mb-3 d-block text-left">{errors.withdrawconfirmpassword}</span>

                                    <div><Button variant="link" className="themeclr f-14 ps-0" onClick={() => setForgotPwd(true)}>Forgot Password</Button></div>
                                    <button className='btn btn-theme mt-3 upper' onClick={() => onWithdrawSubmit()}>Submit</button>
                                </div>

                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TwoFactorAuth2