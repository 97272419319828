import React,{useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import HomeHeader from './HomeHeader';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import toast, { Toaster } from 'react-hot-toast';

import coin1 from '../Assets/Images/coins/coin1.png'
import coin2 from '../Assets/Images/coins/coin2.png'
import coin3 from '../Assets/Images/coins/coin3.png'
import coin4 from '../Assets/Images/coins/coin4.png'
import coin5 from '../Assets/Images/coins/coin5.png'
import coin6 from '../Assets/Images/coins/coin6.png'

import bannerimg from '../Assets/Images/bannerimg.svg'
import graphicon from '../Assets/Images/graph.png'
import aboutimg from '../Assets/Images/aboutimg.svg'
import tick_about from '../Assets/Images/tick_about.svg'
import trade_coin from '../Assets/Images/trade-coin.svg'
import mining from '../Assets/Images/mining.svg'
import percent from '../Assets/Images/percent.svg'
import sponsor from '../Assets/Images/sponsor.svg'


import circle from '../Assets/Images/circle.svg'
import location from '../Assets/Images/location.svg'
import level from '../Assets/Images/level.svg'
import royal from '../Assets/Images/royal.svg'
import binary from '../Assets/Images/binary.svg'
import reward from '../Assets/Images/reward.svg'

import cloud from '../Assets/Images/cloud.png'
import exchange from '../Assets/Images/exchange.png'
import speaker from '../Assets/Images/speaker.png'
import NewPassword from './Modals/NewPassword';
import Withdrawpwd from './Modals/withdrawNewPassword';

import * as GetController from "../Hooks/UseBackendAction/UseGetController"
import Footer from './Footer';
import {AdminMail, backurl} from "../config/env";
// import { getAdminbalance } from '../Hooks/UseWeb3';
import { UseAdminMail } from '../Hooks/UseBackendAction/UseCreateUser.js';


const Home = () => {
    const [newpassword , setNewPassword] = useState(false)
    const[UserName , setUserName] = useState(localStorage.getItem("forgotEmail"))
    
    const queryParams = new URLSearchParams(window.location.search);
    const otp = queryParams.get('code');
    const [linkotp,setlinkOtp] = useState(0);
    const [checkotp,setcheckotp] = useState("")
    const [withdrawpwd , setWithdrawpwd] = useState(false)
    const querywithdrawParams = new URLSearchParams(window.location.search);
    const withdrawotp = querywithdrawParams.get('withdrawcode');
    const [withdrawlinkotp,setwithdrawlinkotp] = useState(0)
    const [withdrawcheckotp,setwithdrawcheckotp] = useState("")
    const [App_Btn,SetApp_Btn] = useState("Buy")
    const [tokeninfo, setTokeninfo] = useState([])
    const [TokenPrice,setTokenPrice] = useState(0)
    const [tmcPrice,settmcPrice] = useState(0)
    const [MaxSupply,setMaxSupply] = useState(0)
    const [TotalSupply,setTotalSupply] = useState(0)
    const [mintPercentage,setmintPercentage] = useState(0)
   
useEffect(()=> {
     getData()
    const decodeotp = Buffer.from(String(otp), 'base64').toString('ascii');
   
    setlinkOtp(decodeotp)
  
   
    if ((Number(decodeotp) > 0  && checkotp == decodeotp)) {

        console.log("condition",(Number(decodeotp) > 0  && checkotp == decodeotp),decodeotp,checkotp);
        setNewPassword(true)
    }else{
        setNewPassword(false)
    }
},[checkotp])

useEffect(()=> {
  
    const decodeotp = Buffer.from(String(withdrawotp), 'base64').toString('ascii');
   
       setwithdrawlinkotp(decodeotp)
  
   
    if ((Number(decodeotp) > 0  && withdrawcheckotp == decodeotp)) {
        console.log("withcondition",(Number(decodeotp) > 0  && withdrawcheckotp == decodeotp),decodeotp,checkotp);
     setWithdrawpwd(true)
    }else{
     setWithdrawpwd(false)
    }
},[withdrawcheckotp])

const gettokeninfo = async () => {
   
   const price = await GetController.getTmcPrice();
   console.log("price",price);
   settmcPrice(Number(price.data))
    const tokeninfo = await GetController.getTokeninfo();

    setTokeninfo(tokeninfo.data)
    const tokenprice = await GetController.getsettinginfo();
    setTokenPrice(Number(tokenprice.data[9].settingvalue))
    setMaxSupply(Number(tokenprice?.data[16]?.settingvalue / 10 ** 18 || 0))
    const totalSupply = await GetController.getTotalSupply("TMC");
    setTotalSupply(parseFloat(totalSupply.data / 10 ** 18))
    var temp = parseFloat(totalSupply.data / 10 ** 18) /Number(tokenprice?.data[16]?.settingvalue / 10 ** 18) * 100
    setmintPercentage(Math.round(temp))
    
}

    const getData = async () =>{
        var UserInfo  =    await GetController.UsergetInfobyname(UserName);
        var regsiterInfo = await GetController.UsergetRegisterInfo(UserName);
      
        
        if(UserInfo.success === true){
           console.log("UserInfo?.data?.Otp",UserInfo?.data?.Otp);
            setcheckotp(UserInfo?.data?.Otp)
            setwithdrawcheckotp(UserInfo?.data?.Otp)
           
        }else{
            console.log("regsiterInfo?.data?.Otp",regsiterInfo?.data?.Otp);
            setcheckotp(regsiterInfo?.data?.Otp) 
            setwithdrawcheckotp(regsiterInfo?.data?.Otp)
        }
    }
    useEffect(() => {
        
        gettokeninfo();
        // getadminbalance();
       
       
    }, []) 


//   const getadminbalance = async() =>{
         
//     const adminbalance = await getAdminbalance();
//     if(adminbalance){
//        const data = {
//             email :AdminMail
//         }
//         await UseAdminMail(data)
//     }
//     }
    return(
        <div id='home' className='home_font'>
        {newpassword && <NewPassword otp={linkotp} onDismiss={() => setNewPassword(false)} />}
        {withdrawpwd && <Withdrawpwd otp={withdrawlinkotp} onDismiss={() => setWithdrawpwd(false)} />}
        
        
       <HomeHeader />
       <div className='banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 left_sec_banner'>
                    <div className='badge badge_blue'>Welcome to</div>
                <h1 className='mt-2'><span>Trade Max</span> <br/> <span>Cryptocurrency</span></h1>
                <p className="banner_p_txt">TMC is a Artificial Intelligence Software System running through Multi Mercantile System.</p>
                <Link to="/" className='btn btn-bord nav-link btn_home_banner'>View More</Link>  
                  </div>
                    <div className='col-12 col-md-6 text-center text-md-right'>
                    <img src={bannerimg} className="mb-4 img-fluid"/>
                    </div>
                </div>
              
            </div>
           
       </div>
       <div className='supportedcurrecy pt-5 homebg_white'>
      
        <div className='container'>
        <div className='row rowupper_banner'>
            <div className='col-12 col-md-6 mb-3'>
                <div className='card card_home_ban'>
                    <div className='card-body py-4'>
                        <p className='card_banner_title'>TMC Price Today</p>
                        <p className='card_banner_desc d-flex align-items-center justify-content-center mb-0'>
                            <span>${(tmcPrice).toFixed(4) || 0}</span>
                            <img src={graphicon} className="img-fluid ms-2"/>

                            </p>

                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6 mb-3'>
                <div className='card card_home_ban'>
                    <div className='card-body py-4'>
                        <p className='card_banner_title'>90% TMC coins are holdings in system</p>
                        <p className='card_banner_desc d-flex align-items-center justify-content-center mb-0'>
                            
                                                      {console.log("mintPercentage",mintPercentage)}
                                                      <span>{(  MaxSupply /100* mintPercentage)}/{MaxSupply}</span>
                            {/* <span>{( TotalSupply * MaxSupply * 100)}/{MaxSupply}</span>  */}
                            
                            </p>

                    </div>
                </div>
            </div>
        </div>
        <h4 className='text-center mb-5 blue_text_tile mt-5' id="about">What is Trade Max Coin?</h4>
        <div className='row'>
         
            <div className='col-12 col-md-6 text-center text-md-left left_img_cen'>
                    <img src={aboutimg} className="mb-4 img-fluid"/>
                    </div>
          
            <div className='col-12 col-md-6'>
                <p className='card_banner_title text-left mb-1'>AI based mining Platform, running through Multi mercantile System</p>
                <p className='card_banner_title_blue'></p>
                <p className='banner_title_blk text-justify'>TMC is an AI based coin with a total supply of 2.1 million. Anyone can join this mining platform and start the mining process. And get better business opportunity through our multi mercantile system.</p>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-4 mb-3'>
                        <div className='card card_home_ban card_about'>
                            <div className='card-body px-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                <img src={tick_about} className="img-fluid me-1"/>
                                
                                    <p className='mb-0'> Limited Supply </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-4 mb-3'>
                        <div className='card card_home_ban card_about'>
                            <div className='card-body px-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                <img src={tick_about} className="img-fluid me-1"/>
                                
                                    <p className='mb-0'> Profit Guarantee </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-4 mb-3'>
                        <div className='card card_home_ban card_about'>
                            <div className='card-body px-1'>
                                <div className='d-flex align-items-center justify-content-center'>
                                <img src={tick_about} className="img-fluid me-1"/>
                                
                                    <p className='mb-0'>Buy Back Option</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* <h4 className='text-center mb-5'> <b>Supported</b> Cryptocurrencies</h4>
            <ul>
            {tokeninfo && tokeninfo.map((item) =>
                <li>
                    <img src={backurl+`/Images/${item.logoURI}`} />
                    <p>{item.symbol}</p> 
                </li>
            )}
               
          </ul> */}
        </div>
        </div>
          
       </div>
       <div className='tmcfeature pt-5 pb-3 homebg_white' id="concept">
            <div className='container pb-md-5'>
            <h4 className='text-center mb-5 blue_text_tile mt-3'>Unique Concept</h4>
            <div className='row'>
                <div className='col-12 col-md-11 mx-auto'>
                <div className='row row_scale'>
                <div className='col-12 col-md-4 px-md-0 sec_normal'>
                <div className='card card_home_ban card_concept card_left py-3'>
                    <div className='card-body'>
                        <div className='d-flex justify-content-between'>
                            <img src={trade_coin} className="img-fluid mr-3 img_coin_new" />
                            <div>
                            <p class="card_banner_title mb-0 text-right">Basic - $25</p>
                            <p class="card_banner_title_blue mb-0 text_big_blu text-right">You will get $25 worth of TMC</p>
                            </div>
                        </div>
                        <div className='card card_tarn mt-3'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 col-md-6 bor_right_md'>
                            <p class="card_banner_title_blue mb-0">Circle Income</p>
                            <p class="card_banner_title_sm mb-0">5%</p>

                                    </div>
                                    <div className='col-12 col-md-6'>
                            <p class="card_banner_title_blue mb-0">Land Mark Income</p>
                            <p class="card_banner_title_sm mb-0">5% - 10%</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card card_tarn mt-3'>
                            <div className='card-body'>
                                <div className='row'>
                                    {/* <div className='col-12 col-md-6 bor_right_md'>
                            <p class="card_banner_title_blue mb-0">Level Income</p>
                            <p class="card_banner_title_sm mb-0">25%</p>

                                    </div> */}
                                    
                                    <div className='col-12 col-md-6'>
                            <p class="card_banner_title_blue mb-0">Royal Pool Income</p>
                            <p class="card_banner_title_sm mb-0">10%</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className='row mt-3'>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Royal Pool Income - 10%
                                </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Binary Income - 5%
                                </span>
                                </div>
                            </div>
                            
                        </div> */}
                        <div className='mt-1 card card_grey '>
                            <div className='card-body'>
                               
                                <div className='row'>
                                
                                    <div className='col-12 col-md-6 '>
                            <p class="card_banner_title_blue mb-0">Binary Income </p>
                            <p class="card_banner_title_sm text-center">5%</p>

                                    </div>
                                    <div className='col-12 col-md-6'>
                                    <p className='mb-0'>Daily Limit $75 worth of TMC</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className='col-12 col-md-4 bg_blue_card_sec px-md-0'>
                <div className='card card_home_ban card_concept py-md-2'>
                    <div className='card-body pb-0'>
                        <div className='d-flex justify-content-between'>
                            <img src={trade_coin} className="img-fluid mr-3 img_coin_new" />
                            <div>
                            <p class="card_banner_title mb-0 text-right">Trade Max Coin</p>
                            <p class="card_banner_title_blue mb-0 text_big_blu text-right">Total Supply - 2.1 Million TMC</p>
                            </div>
                        </div>
                        <div className='card card_tarn mt-1'>
                            <div className='card-body py-2'>
                                <div className='row'>
                                    <div className='col-12 col-md-12 text-center'>
                            <p class="card_banner_title_blue mb-0">Pre-Mined</p>
                            <p class="card_banner_title_sm mb-0 text-center">{( MaxSupply /100* mintPercentage)} TMC</p>

                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Smart Contract- {Math.round(MaxSupply - TotalSupply)} TMC
                                </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Coin Value Started - $20
                                </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Price Variation - 0.0025 Per Hour
                                </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Price Variation - 0.06 Per Day
                                </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Coin Value - $41.9 in One Year
                                </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Buy Back - Minus 5% of Market Price 
                                </span>
                                </div>
                            </div>
                        </div>
                        <div className='card card_grey'>
                            <div className='card-body'>
                                <p className='mb-0'>Only 10% TMC will get each month for Sell/withdrawal/Transfer</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className='col-12 col-md-4 px-md-0 sec_normal'>
                <div className='card card_home_ban card_concept card_right py-3'>
                    <div className='card-body'>
                        <div className='d-flex justify-content-between'>
                            <img src={trade_coin} className="img-fluid mr-3 img_coin_new" />
                            <div>
                            <p class="card_banner_title mb-0 text-right">Prime - $100</p>
                            <p class="card_banner_title_blue mb-0 text_big_blu text-right">You will get $100 worth of TMC</p>
                            </div>
                        </div>
                        <div className='card card_tarn mt-3'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12 col-md-6 bor_right_md'>
                            <p class="card_banner_title_blue mb-0">Circle Income</p>
                            <p class="card_banner_title_sm mb-0">10%</p>

                                    </div>
                                    <div className='col-12 col-md-6'>
                            <p class="card_banner_title_blue mb-0">Land Mark Income</p>
                            <p class="card_banner_title_sm mb-0">5% - 10%</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card card_tarn mt-3'>
                            <div className='card-body'>
                                <div className='row'>
                                    {/* <div className='col-12 col-md-6 bor_right_md'>
                            <p class="card_banner_title_blue mb-0">Level Income</p>
                            <p class="card_banner_title_sm mb-0">25%</p>

                                    </div> */}
                                    
                                    <div className='col-12 col-md-6'>
                            <p class="card_banner_title_blue mb-0">Royal Pool Income</p>
                            <p class="card_banner_title_sm mb-0">10%</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='row mt-3'>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Royal Pool Income - 10%
                                </span>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3'>
                                <div className='d-flex align-items-center'>

                              
                                <span className='bg_grey_tick me-1'>
                                <i className="fa fa-check" aria-hidden="true"></i>
                                </span>
                                <span className='bg_text_sm_grey'>
                                Binary Income - 10%
                                </span>
                                </div>
                            </div>
                           
                        </div> */}
                        <div className='mt-1 card card_grey'>
                            <div className='card-body'>
                                <div className='row'>
                                <div className='col-12 col-md-6 '>
                            <p class="card_banner_title_blue mb-0">Binary Income </p>
                            <p class="card_banner_title_sm text-center">10%</p>

                                    </div>
                                    <div className='col-12 col-md-6'>
                                    <p className='mb-0'>Daily Limit $300 worth of TMC</p>

                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
                </div>
            </div>
           
                {/* <PayPalScriptProvider options={
                                 { "client-id": "AYjv39gWPZjnfOT6oVXxn4lkTXMIUcYgDlrCjuucIfaL3ukbPy_AEwBc1Q1OIRVHbHeNm7hAvtsGhWiG" ,
                                 }}>
                              <PayPalButtons style={{ layout: "vertical" }} 
                              // disabled={App_Btn === "process" || App_Btn === "done" ? true : false}
                              fundingSource="card"
                            //   forceReRender={[collection.TokenPrice]}
                                    createOrder={(data, actions) => {
                                       SetApp_Btn('process')
                                       return actions.order.create({
                                          purchase_units: [
                                             {
                                                   amount: {
                                                      currency_code:'USD',
                                                    //   value: collection.TokenPrice,
                                                      value: "1.99",
                                                   },
                                             },
                                          ],
                                       }) .then((orderId) => {
                                          console.log(`Transaction order id by ${orderId}`);

                                          return orderId;
                                      });
                                 }}
                                 onApprove={(data, actions) => {
                                       return actions.order.capture().then((details) => {
                                          const name = details.payer.name.given_name;
                                          const id =  details.purchase_units[0].payments.captures[0].id
                                        //    _Buy(name,id)
                                           console.log("name,id",name,id);
                                          console.log(`Transaction completed by ${JSON.stringify(details)}${name}`);
                                       });
                                 }}
                                 onError={(err) => {
                                    console.log(`Transaction err ${err}`);
                                    SetApp_Btn('try')
                                    toast.error(err,{autoClose:1000})

                                 }}
                                 />
                              </PayPalScriptProvider> */}
               
            </div>
        </div>
        <div className='tmcfeature pb-0 homebg_white' id="opportunity">
            <div className='container pb-md-5'>
            <h4 className='text-center mb-5 blue_text_tile mt-5'>Multi Mercantile System</h4>
            <div className='row row_compens'>
                <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    <div className='card card_home_ban'>
                        <div className='card-body px-3 px-md-4 py-3 py-md-5'>
                        <img src={circle} className="img-fluid icon_compens" />
                        <p className='card_comp_title'>Circle Income</p>
                        <p class="bg_text_md_grey">Basic Membership $25</p>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">5% from your each and every direct referrals</span>
                        </div>
                        <p class="bg_text_md_grey">Prime Membership $100</p>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">10% from your each and every direct referrals</span>
                        </div>

                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    <div className='card card_home_ban'>
                        <div className='card-body px-3 px-md-4 py-3 py-md-5'>
                        <img src={location} className="img-fluid icon_compens" />
                        <p className='card_comp_title'>Landmark Income</p>
                        <p class="bg_text_md_grey">5% from your Team</p>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey"><b>Eligibility :</b> 10 Direct Referrals, after that 5% Landmark Income will get from next new Joining's from your Team.</span>
                        </div>
                        <p class="bg_text_md_grey">10% from your Team</p>
                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey"><b>Eligibility :</b> Prime Membership and 50 Direct Referrals, after that 10% Landmark Income will get from next new Joining's from your Team. </span>
                        </div>

                        <div class="d-flex mb-0">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey"><b>Income Cut Off:</b> when your Direct/Team Member in same position</span>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    <div className='card card_home_ban'>
                        <div className='card-body px-3 px-md-4 py-3 py-md-5'>
                        <img src={level} className="img-fluid icon_compens" />
                        <p className='card_comp_title'>Level Income</p>
                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">5% Referral income for Basic Plan</span>
                        </div>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">Basic Package ($25) from each and every direct Referral</span>
                        </div>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">10% Referral income for Prime Plan</span>
                        </div>

                        <div class="d-flex mb-0">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">Prime Package ($100) from each and every direct Referral</span>
                        </div>
                        </div>
                    </div>
                </div> */}
                <div className='col-12 col-md-6 col-lg-4 mb-3'>
                    <div className='card card_home_ban'>
                        <div className='card-body px-3 px-md-4 py-3 py-md-5'>
                        <img src={royal} className="img-fluid icon_compens" />
                        <p className='card_comp_title'>Royal Pool Income</p>
                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">10% of total turn over (Memberships) keep in a pool and equally distribute to all eligible achievers on weekly basis. </span>
                        </div>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey"><b>Eligibility:</b> (Prime/Basic) Team Business Volume when reaching $25000. $12,500 in Left Groups and  $12,500 in Right Groups</span>
                        </div>

                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-6 mb-3'>
                    <div className='card card_home_ban'>
                        <div className='card-body px-3 px-md-4 py-3 py-md-5'>
                        <img src={binary} className="img-fluid icon_compens" />
                        <p className='card_comp_title'>Binary Income</p>
                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">Basic Memberships will get 5% of Binary Matrix Matching, Daily Capping limit is $75 worth TMC. </span>
                        </div>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">Prime Memberships will get 10% of Binary Matrix Matching, Daily Capping limit is $300 worth TMC.
Power side will be carry forwarded to next closing weaker side will be flush out.</span>
                        </div>

                       

                       
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-6 mb-3'>
                    <div className='card card_home_ban'>
                        <div className='card-body px-3 px-md-4 py-3 py-md-5'>
                        <img src={reward} className="img-fluid icon_compens" />
                        <p className='card_comp_title'>Rewards</p>
                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">Abroad Trip (Various Locations )</span>
                        </div>

                        <div class="d-flex mb-3">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">$ 5000 Matching in Binary Team</span>
                        </div>

                        <div class="d-flex mb-0">
                        <span class="bg_grey_tick me-1">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                        <span class="bg_text_sm_grey">$ 5000 Worth Voucher -  $ 25000 Matching in Binary Team</span>
                        </div>

                        
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className='tmcfeature pb-0 homebg_white'>
            <div className='container pb-5'>
            <h4 className='text-center mb-5 blue_text_tile mt-5'>Staking Benifits</h4>
            <div className='row'>
         
            <div className='col-12 col-md-6 text-center text-md-left left_img_cen'>
                    <img src={mining} className="mb-4 img-fluid"/>
                    </div>
          
            <div className='col-12 col-md-6 mt-md-5'>
                <p className='banner_title_blk text-justify'>Staking is one of the best investment ideas to get rewarded by holding your TMC coin, we have different types of staking plans for 1, 2 and 3 years, you can earn passive income with TMC coin. TradeMax makes it easy to put your crypto to work for you.</p>
                <p className='banner_title_blk font_weight_700'>Investors get</p>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12 mb-3'>
                        <div className='card card_home_ban card_mining'>
                            <div className='card-body px-3'>
                                <div className='d-flex align-items-center justify-content-start'>
                                <img src={percent} className="img-fluid me-1"/>
                                
                                    <p className='mb-0'>
                                        <span className='bold_letter'>
                                        Earn 5% Every Quarter   
                                        </span>
                                        {/* <span className='thin_letter ms-2'>
                                        Capital returns after three years
                                        </span> */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 col-lg-12 mb-3'>
                        <div className='card card_home_ban card_mining'>
                            <div className='card-body px-3'>
                                <div className='d-flex align-items-center justify-content-start'>
                                <img src={percent} className="img-fluid me-1"/>
                                
                                    <p className='mb-0'>
                                        <span className='bold_letter'>
                                        Capital returns after three years
                                        </span>
                                        {/* <span className='thin_letter ms-2'>
                                            for one year fixed
                                        </span> */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                   
                </div>
            </div>
            </div>
            </div>
        </div>
        <div className='tmcfeature pb-0 homebg_white'>
            <div className='container'>
            <h4 className='text-center mb-5 mb-lg-0 mb-xl-5 blue_text_tile'>Sponsor Rewards</h4>
            <div className='row row_rev'>           
          
            <div className='col-12 col-md-6 spon_sec_cen'>
                <p className='banner_title_blk text-justify mb-4'>Trademax has prepared a great business opportunity for you. You can get mining and staking benefits and beyond all this get sponsorship benefits in a big way. The sponsor is a leader and you will be better rewarded when you think like a business magnet.</p>
              
                {/* <div class="d-flex align-items-center justify-content-center mb-3">
                    <img src={tick_about} class="img-fluid me-2" />
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sus tincidunt ultrices.</p>
                        </div> */}

                
                        {/* <div class="d-flex align-items-center justify-content-center">
                    <img src={tick_about} class="img-fluid me-2" />
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sus tincidunt ultrices.</p>
                        </div> */}

            </div>

            <div className='col-12 col-md-6 text-center text-md-left left_img_cen'>
                    <img src={sponsor} className="mb-4 img-fluid sponsor_img"/>
                    </div>
            </div>
            </div>
        </div>
        <Footer />
      

    </div>
    )
}

export default Home